import React, { Component } from 'react';
import ReactTable from "react-table";
import './styles.css';
import "react-table/react-table.css";
import ModalDesgloseUser from "./modalDesgloseUser"
import request from '../../utils/request';
import constantes from '../../utils/constantes';

class TablaUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
      }
      async componentDidMount(){
        await request(constantes("URL_API") + "/graphql?query=%7B%0A%20%20entradas(limit%3A0)%7B%0A%20%20%20%20_id%0A%20%20%20%20title%0A%20%20%7D%0A%20%20exams(limit%3A0)%7B%0A%20%20%20%20_id%0A%20%20%20%20name%0A%20%20%7D%0A%7D", { method: "GET" })
        .then(response => 
            this.setState({entradasRef: response.data.entradas,
            examsRef:response.data.exams}))  

    }

  render() {
    return (
              <ReactTable                
                data={this.props.users.map(u => {
                  u.ultimoCurso = u.avancecursos.length !== 0 && u.avancecursos.sort(function (a, b) {a = new Date(a.init_date); b = new Date(b.init_date);}) [0].cursos.nombre;
                  u.progreso = Math.round((u.avancecursos.length !== 0 &&
                    (u.avancecursos.sort(function (a, b) {
                    a = new Date(a.init_date);
                    b = new Date(b.init_date);                          
                  }) [0].avance.filter(e => e.status ==="completado").length/u.avancecursos.sort(function (a, b) {
                    a = new Date(a.init_date);
                    b = new Date(b.init_date);                          
                  })[0].avance.length)*100)*100)/100;
                  u.estado = u.avancecursos.length !== 0 && u.avancecursos.sort(function (a, b) {
                  a = new Date(a.init_date);
                  b = new Date(b.init_date);                          
                  }) [0].estao;
                  u.totalAprobados = u.avancecursos.filter(c =>c.estao==='completado').length;
                  return u 
                  })}
                columns={[
                  {
                    Header: 'Email',
                    accessor: "email",
                    headerClassName: "email",
                    width: 220,
                    Cell: row => (
                      <div className="email">
                        {row.value}
                      </div>
                    )
                  },
                  {
                    Header: "Ultimo curso",
                    accessor: "ultimoCurso",
                    Cell: row => (
                      <div className="published_date">
                        {
                          row.value 
                        }
                      </div>
                    )
                  },
                  {
                    Header: "Progreso",
                    accessor: "progreso",
                    width: 120,
                    Cell: row => (
                      <div className="type">
                        <span>
                        { row.value + "%"}
                      </span>
                      </div>
                    )
                  },                 
                  {
                    Header: "Estado",
                    accessor: "estado",
                    width: 120,
                    Cell: row => (
                      <div className="estado">
                        {
                          row.value}
                      </div>
                    )
                  },
                  {
                    Header: "Total de aprobados",
                    accessor: "totalAprobados",
                    Cell: row => (
                      <div className="type">
                        {row.value} de {row.original.avancecursos.length}
                      </div>
                    )
                  },
                  {
                    Header: "Historial",
                    accessor: "avancecursos",
                    width: 120,
                    Cell: row => (
                      <div className="published_date">
                      <ModalDesgloseUser user={row.original} entradasRef={this.state.entradasRef} examsRef={this.state.examsRef}/>                        
                      </div>
                    )
                  }
                ]}
                defaultSorted={[
                  {
                    id: "publish_date",
                    desc: true
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
    )
            }
}

export default TablaUsers;