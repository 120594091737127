import React from 'react';
import './styles.css';

export default function AnalyticBoxUsers (props){

let usersActivos = props.users!==undefined?props.users
                  .filter(e =>
                      e.logs.length>0
                      &&new Date(e.logs[0].createdAt)>props.initDate 
                      && new Date(e.logs[0].createdAt)<props.endDate
                      ).length:0; 
// props.users.map(e => console.log(e.logs))

let usersTotal =  props.users!==undefined?props.users.length:0; 
    return(
        <div>
          <div className="rounded_contAn contents shadow col statCol">
                    <div >
                      <span className="statText">{props.texto}</span>  
                      <div className="col text-right numStat">Activos: {usersActivos}</div>
                      <div className="col text-right numStat">Totales: {usersTotal}</div>
                    </div>
                </div>
        </div>
    )
  }

    