import React, { Component } from 'react';
import './styles.css';
import "react-table/react-table.css";
//import { Progress } from 'react-sweet-progress';
import { Progress } from 'reactstrap';
var moment = require('moment');
class UsuariosActivos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            refDate: moment(new Date()).subtract(7, 'days').utc().format(),            
        };
        
      }


  render() {
    let usersActivos = this.props.users!==undefined?this.props.users
                    .filter(e =>
                        e.logs.length===1&&e.logs[0].createdAt > this.state.refDate
                    ).length:0;              
    let usersInactivos = this.props.users!==undefined?this.props.users
                    .filter(e =>
                        (e.logs.length===1&&e.logs[0].createdAt < this.state.refDate) || (e.logs[0]===undefined)
                    ).length:0;
    let usersTotal =  this.props.users!==undefined?this.props.users.length:0;    
    let percentActivos = this.props.users!==undefined?Math.round((usersActivos*100)/usersTotal):0
    let percentInactivos = this.props.users!==undefined?Math.round((usersInactivos*100)/usersTotal):0

    return (
        <div className="container statUsers" id="statUsers">

            <div className="row moduloStat">
                <div className="col-12">
                    <div className='row'>
                        <div className="col-6 nameStat">Usuarios activos</div>
                        <div className="col text-right numStat">{usersActivos}</div>
                    </div>
                </div>
                <div className="col-12 text-center">
                    <Progress value={percentActivos} color='success' />
                </div>
            </div>

            <div className="row moduloStat">
                <div className="col-12">
                    <div className='row'>
                        <div className="col-6 nameStat">Usuarios inactivos</div>
                        <div className="col text-right numStat">{usersInactivos}</div>
                    </div>
                </div>
                <div className="col-12 text-center">
                    <Progress value={percentInactivos} color='danger' />
                </div>
            </div>

            <div className="row masInfo">
                <div className="col-12">
                    <div className='row dcha'>
                        <span className="nameStat">Usuarios &nbsp;&nbsp;</span>
                        <span className="numStat"> {usersTotal}</span>
                    </div>
                </div>
            </div>


        </div>
    )
            }
}

export default UsuariosActivos;