import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

export default class ModalFinWebinar extends Component {
    constructor(props){
        super(props);
    }

    
    render() {
        return(
            <Modal isOpen={this.props.modalOpen} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>
                    Finalizar Webinar
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <p>Si finalizas el webinar ya no podra volver a retransmitir en directo aquí. Se procedera al guardado del video.</p>
                        <p>¿Estas seguro de finalizar el Webinar?</p>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button color="primary" className="btn-gradient-style" onClick={this.props.finalizarwebinar}>Finalizar</Button>
                    <Button color="danger" onClick={this.props.toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        );    
    }
}