import React, { Component } from 'react';
import TablaCursos from "./tablaCursos";
import TablaUsers from "./tablaUsers";
import UsuariosActivos from "./usuariosActivos";
import request from '../../utils/request';
import constantes from '../../utils/constantes';
import Loading from '../../components/Loading';
import './styles.css';
import "react-table/react-table.css";
import BlockMap from '../../components/BlockMap';
import Select from "react-select";


class SmartEstadistica extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cursos: [],
      stores: [],
      selectBloque: [],
      limit: 10,
      fechaRef: null,
      start: 0,
      total: 0,
      model: "entradas",
      loading: false,
      area_admin: true,
      users: [],
      selectedsolviarol:'',
      solviarols:[]
    };
  }

  setLoading = (loading) =>{
    this.setState({
      loading
    });
  }
  handleChangeRol = async selectedOption => {
    await this.setState({ selectedsolviarol: selectedOption });
    if (selectedOption.length>0) {
      let dataFiltro3 = this.state.users2.filter((user) => user.solviaRol&& selectedOption.some(so => so.value===user.solviaRol._id))
      this.setState({ users: dataFiltro3 })
    } else {
      this.setState({ users: this.state.users2 })
    }
  };
  filtraCursos = (value) => {
    if (value.target.value !== "" || value.target.value !== undefined) {
      let dataFiltro = this.state.cursos2.filter(element => element.nombre.includes(value.target.value))     
      this.setState({ cursos: dataFiltro })
    } else {
      this.setState({ cursos: this.state.cursos2 })
    }
  }
  filtraTiendas = (value) => {
    if (value.target.value !== "" || value.target.value !== undefined) {
      let dataFiltro2 = this.state.stores2.filter(array => array.user.email.includes(value.target.value))
      this.setState({ stores: dataFiltro2 })
    } else {
      this.setState({ stores: this.state.stores2 })
    }
  }
  filtraUsers = (value) => {
    if (value.target.value !== "" || value.target.value !== undefined) {
      let dataFiltro3 = this.state.users2.filter(user => user.email.includes(value.target.value))
      this.setState({ users: dataFiltro3 })
    } else {
      this.setState({ users: this.state.users2 })
    }
  }

   componentDidMount = () =>{
    this.setState({
      loading: true
    });
    var consulta = "/graphql?query=%7B%0A%20%20cursos(limit%3A0)%7B%0A%20%20%20%20_id%0A%20%20%20%20nombre%0A%20%20%20%20avancecursos%7B%0A%20%20%20%20%20%20%20_id%0A%20%20%20%20user%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20name%0A%20%20%20%20%20%20email%0A%20%20%20%20%7D%0A%20%20%20%20estao%0A%20%20%20%20avance%0A%20%20%20%20%7D%0A%20%20%7D%0A%20%20stores(limit%3A0)%7B%0A%20%20%20%20_id%0A%20%20%20%20name%0A%20%20%20%20dit%0A%20%20%20%20address%0A%20%20%20%20poblacion%0A%20%20%20%20provincia%0A%20%20%20%20cp%0A%20%20%20%20latitud%0A%20%20%20%20longitud%0A%20%20%20%20users%7B%0A%20%20%20%20%20%20email%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20avancecursos%7B%20%20%20%20%20%20%20%20%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%20%20init_date%0A%20%20%20%20%20%20%20%20end_date%0A%20%20%20%20cursos%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20nombre%0A%20%20%20%20%7D%0A%20%20%20%20estao%0A%20%20%20%20avance%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%0A%20%20users(limit%3A0)%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20logs(limit%3A1,%20sort%3A%22createdAt%3Adesc%22)%7B%0A%20%20%20%20%20%20createdAt%0A%20%20%20%20%20%20pagina%0A%20%20%20%20%7D%0A%20%20%20%20%20%20email%0A%20%20%20%20%20%20avancecursos%7B%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%20%20estao%0A%20%20%20%20%20%20%20%20avance%0A%20%20%20%20%20%20%20%20cursos%7B%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%20%20%20%20nombre%0A%20%20%20%20%20%20%20%20%7D%20%20%20%20%20%20%0A%20%20%20%20%20%20%7D%0A%20%20%20%20solviaRol%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%20%20avancetests%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20chosenanswers%0A%20%20%20%20%20%20curso%7B%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%20%20%0A%20%20%7D%0A%20%20solviarols%7B%0A%20%20%20%20_id%0A%20%20%20%20name%0A%20%20%7D%0A%7D";
    request(constantes("URL_API") + consulta, { method: "GET" })
      .then((response) => {  
          let roles = response.data.solviarols.map(solviarol => {
            return {
              value: solviarol._id,
              label: solviarol.name
            };
          });  
          //roles.unshift({value:"",label:"Ninguno"})     
          this.setState({
            cursos: response.data.cursos,
            cursos2: response.data.cursos,
            stores: response.data.stores,            
            stores2: response.data.stores,
            users: response.data.users,            
            users2: response.data.users,
            solviarols: roles,            
            solviarols2: roles,            
            loading: false,
         })
      
        });
  }
  

  render() {
    return (
      <div className="main_content listado">
        <div>
          <h3>Analítica</h3>
        </div>

        {/* Mapa de tienda        */}
        <div className="row" id="analyticUp">
          <div className="rounded_cont contents shadow col">
            <div className="row">
              <div className="col">
                {/* <div><h4>Mapa de tienda</h4></div> */}
                <div style={{ height: "400px" }}>
                  <Loading loading={this.state.loading} />
                  <BlockMap
                    isMarkerShown={true}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC6Umm0WckCCFOWjff7TLvU4UHmhnxqd7I&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `100%` }} />}
                    mapElement={
                      <div
                        style={{
                          height: `100%`,
                          overflow: `hidden`,
                          borderRadius: `15px`
                        }}
                      />
                    }
                    stores={this.state.stores}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Datos de usuarios Activos        */}
          <div className="rounded_cont contents shadow col">
            <div className="row">
              <div className="col">
                <div>
                  <h4>Estadísticas de usuarios</h4>
                </div>
                <div style={{ height: "400px" }}>
                  <UsuariosActivos users={this.state.users2} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Progresos de usuario  */}
        <div className="rounded_cont contents shadow col-12">
          <div className="row" style={{ padding: "20px" }}>
            <div className="col">
              <div>
                <h4>Datos según Usuarios</h4>
              </div>
              <div className="row">
                <div className="col-8">
                <input
                  type="text"
                  placeholder="email"
                  onChange={this.filtraUsers}
                  className="form-control list-search"
                />
                </div>
                <div className="col">
                <Select
                  isMulti
                  options={this.state.solviarols}
                  className="form-control rounded-select rounded_cont shadow filterRol"
                  onChange={this.handleChangeRol}
                  value={this.state.selectedsolviarol}
                  placeholder={"Rol"}
                />                
              </div>
              <div className="col-12 infoAprobados">
              <span className="">
                  <b>Examenes aprobados:</b>{" "}
                  {
                    this.state.users
                      .filter(e => e.avancecursos.length > 0)
                      .map(e => e.avancecursos)
                      .flat()
                      .filter(e => e.estao === "completado").length
                  }{" "}
                  de
                  {" " +
                    this.state.users
                      .filter(e => e.avancecursos.length > 0)
                      .map(e => e.avancecursos)                      
                      .flat()
                      .filter(e => e.estao === "completado" || e.estao==="fallido").length +
                    " examenes realizados entre " +
                    this.state.users.filter(e => e.avancecursos.length > 0)
                      .length +
                    " usuarios."}
                </span>
              </div>
              <div className="col-12"><TablaUsers
                users={this.state.users.filter(
                  e => e.avancecursos.length > 0
                )}
              />
              </div>
            </div>
          </div>
        </div>
        {/* Progresos de Curso  */}
        <div className="rounded_cont contents shadow col-12">
          <div className="row" style={{ padding: "20px" }}>
            <div className="col">
              <div>
                <h4>Datos de cursos</h4>
              </div>
              <div className="row">
                <input
                  type="text"
                  placeholder="Nombre del curso"
                  onChange={this.filtraCursos}
                  className="form-control list-search"
                />
              </div>
              <TablaCursos avancecursos={this.state.cursos} />
            </div>
          </div>
        </div>
      </div>
      </div>

    );
  }
}

export default SmartEstadistica;