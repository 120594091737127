import React, { Component } from 'react';
// import request from '../../utils/request';
import constantes from '../../utils/constantes';
import auth from '../../utils/auth';
import {Container, Row, Col,Input, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import MdSend from 'react-ionicons/lib/MdSend';
import "./index.css"
import { socketConnect } from 'socket.io-react';
import moment from 'moment';
import 'moment/locale/es';

const SocketEndpoint = constantes("URL_API");
const io = require('socket.io-client');

class NewChatComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            jwt: '',
            comentariosub: '',
            cursoName: 'curso',
            loading: false
        };
    }  
    recargaMsg = async () => {    
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.state.jwt}`
        }
        const response2 = await ( await fetch(`${constantes("URL_API")}/graphql?query=%7B%0A%20%20comentarios(limit%3A0,%20where%3A%7Barea_admin%3A%22smartlearning%22,webinar%3A%22${this.state.webinar}%22%20%7D)%7B%0A%20%20%20%20comments%0A%20%20%20%20publish_date%20%20%20%20%0A%20%20%20%20webinar%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%20%20user%7B%0A%20%20%20%20%20%20name%0A%20%20%20%20%20%20surname%0A%20%20%20%20%20%20picture%7B%0A%20%20%20%20%20%20%20%20url%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%20%20%0A%7D`, { headers }) ).json();
        this.setState({
            comentarios: response2.data.comentarios.reverse(),
        });
      }   


    componentDidMount = async()=>{
        this.setState({loading:true})
        const userID = auth.getUserInfo()._id;
        const jwt = auth.getToken();
        const webinar = this.props.webinar;
        this.setState({ jwt:jwt, userID:userID, webinar:webinar });
    
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
        } 
        
        const response = await ( await fetch(`${constantes("URL_API")}/graphql?query=%7B%0A%20%20comentarios(limit%3A0,%20where%3A%7Barea_admin%3A%22smartlearning%22,webinar%3A%22${webinar}%22%20%7D)%7B%0A%20%20%20%20comments%0A%20%20%20%20publish_date%20%20%20%20%0A%20%20%20%20webinar%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%20%20user%7B%0A%20%20%20%20%20%20name%0A%20%20%20%20%20%20surname%0A%20%20%20%20%20%20picture%7B%0A%20%20%20%20%20%20%20%20url%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%20%20%0A%7D`, { headers }) ).json()
        console.log(response)
        this.setState({
            comentarios: response.data.comentarios.reverse(),
            loading:false
        });
        const socket = io(SocketEndpoint, {
            transports: ['websocket'],
          });
      
          socket.on('notification', msg => {
              this.recargaMsg();
          });
    }
    async guardaDatos() {
        
                let coment = this.state.comentariosub
                const user = this.state.userID                
                const headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.state.jwt}`
                }
                const response = await ( await fetch(`${constantes("URL_API")}/comentarios/`, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify({ comments: coment, webinar:this.state.webinar, user, publish_date: new Date(), area_admin: "smartlearning" })
                }) ).json()
                // console.log(response)
                this.setState({
                    comentariosub: ''})
                //console.log("recargado")
            }
    keyPress = (e) => {
        if (e.keyCode === 13) {
            this.guardaDatos();
        }
             }
            
      

    

    render() {
        moment.locale('es');

        if (this.state.error) {
            return <div className="rounded_cont"><p>{this.state.error}</p></div>;
        } else if (this.state.loading) {
            return <div className="rounded_cont"><p>Cargando...</p></div>;
        }

        // return (
        //     <div className="chat">
        //         <div style={{height:'38vh', overflow:'auto'}}>
        //         {
        //             this.state.comentarios&&this.state.comentarios.map((msg,i) => {
        //                 return(
        //                     <div key={i} className="response-box">
        //                         <img alt="usuario" className="avatar-user" src={(msg.user&&msg.user.picture&&msg.user.picture.url ) || constantes("URL_API")+"/uploads/userplaceholder.jpg"} />
        //                         <div className="response">
        //                             <p className="username">{msg.user === null&&"Usuario dado de Baja"}{msg.user&&msg.user.name} {msg.user&&msg.user.surname}</p>
        //                             <p className="response-user">{msg.comments}</p> 
        //                             <p className="chatmessage">{moment(msg.publish_date).format('DD MMMM YYYY, h:mm:ss a')}</p> 
        //                         </div>
        //                     </div>
        //                 )
        //             })
        //         }
        //         </div>
        //         <div className="write-message-box" style={{position:'absolute', bottom:0, width:'100%'}}>
        //             <input placeholder=" Escribe un mensaje" className="write-message" 
        //                 onChange={(comentario) => this.setState({comentariosub: comentario.target.value})}
        //                 onKeyDown={this.keyPress} 
        //                 value={this.state.comentariosub}/>
                    
        //             <div className="enter-chat" onClick={() => this.guardaDatos()}>
        //                 <MdSend size={30} color={"gainsboro"}/> 
        //             </div>
        //         </div>
        //     </div>
        // );


        return (
            <Container >

                <Row style={{minHeight:150, marginTop:50, marginBottom:20}} className="card">
                    <Col>
                    {console.log(this.state.messages)}
                    {   this.state.messages && this.state.messages.length === 0 ? 
                        <h4 style={{color:'gainsboro', textAlign:'center', marginTop:20}}>Sin Mensajes en el webinar</h4> 
                        :
                        this.state.comentarios&&this.state.comentarios.map((msg,i) => {
                            return(
                                <div key={i} className="response-box">
                                    <img alt="usuario" className="avatar-user" src={(msg.user&&msg.user.picture&&msg.user.picture.url ) || constantes("URL_API")+"/uploads/userplaceholder.jpg"} />
                                    <div className="response">
                                        <p className="username">{msg.user === null&&"Usuario dado de Baja"}{msg.user&&msg.user.name} {msg.user&&msg.user.surname}</p>
                                        <p className="response-user">{msg.comments}</p> 
                                        <p className="chatmessage">{moment(msg.publish_date).format('DD MMMM YYYY, h:mm:ss a')}</p> 
                                    </div>
                                </div>
                            )
                        })
                    }
                    </Col>
                </Row>
                <Row>
                    <Col xs="9">
                        <Input value={this.state.actualMessage}
                            onKeyPress={this.handleKeyPress} 
                            onChange={(comentario) => this.setState({comentariosub: comentario.target.value})}
                            onKeyDown={this.keyPress} 
                            value={this.state.comentariosub}
                            placeholder="Mensaje" />
                    </Col>
                    <Col xs="3">
                        <Button className="btn-gradient-style" color="secondary" onClick={() => this.guardaDatos()}>Enviar</Button>
                    </Col>
                </Row>
            </Container>
        );
    }
}
export default socketConnect(NewChatComponent)