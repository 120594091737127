import React, { Component } from 'react';
import ReactTable from "react-table";
import request from '../../utils/request';
import constantes from '../../utils/constantes';
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import auth from '../../utils/auth';

export default class TablaParticipantes extends Component {
    constructor(props){
        super(props);
        this.state = {
            users: [],
            loading: true,
            modalOpen: false
        }
        
    }

    remember = async (row) => {
        if(row.original.token !== ""){
            let noti = {
                to: row.original.user.token,
                title: "Recordatorio de invitación para el Webinar "+this.state.webinar.name+".",
                body: "Buenas "+row.original.user.name+", acepte la convocatoria.",
                data: {acceptWebinar:this.state.webinar._id},
                sound: "default"
            }

            fetch('https://exp.host/--/api/v2/push/send', { 
                method: 'POST',
                body:    JSON.stringify(noti),
                headers: { 
                    'accept': 'application/json',
                    'accept-encoding': 'gzip, deflate',
                    'content-type': 'application/json'
                }
            });
        }
    }

    toggle = () => {
        this.setState({
            modalOpen: !this.state.modalOpen
        })
    }

    showTable = () => {
        const webid = this.props.row.row._id;
        const courseID = this.props.courseID;
        this.setState({
            modalOpen: true
        });
        if(!courseID){
            let graph = "/graphql?query=%7B%0A%20%20webinar(id%3A%22"+webid+"%22)%7B%0A%20%20%20%20_id%0A%20%20%20%20name%0A%20%20%20%20avwebinars%7B%0A%20%20%20%20%20%20state%0A%20%20%20%20%20%20user%7B%0A%20%20%20%20%20%20%20%20name%0A%20%20%20%20%20%20%20%20surname%0A%20%20%20%20%20%20%20%20email%0A%20%20%20%20%20%20%20%20token%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D"
            request(constantes("URL_API")+graph, {
                method: "GET"
            }).then(response => {
                this.setState({
                    webinar: response.data.webinar,
                    avwebinars: response.data.webinar.avwebinars,
                    loading: false
                })
            })
        }else {
            let graph = "/graphql?query=%0A%7B%0A%20%20curso(id%3A%22"+webid+"%22)%7B%0A%20%20%20%20_id%0A%20%20%20%20avancecursos%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20user%7B%0A%20%20%20%20%20%20%20%20name%0A%20%20%20%20%20%20%20%20surname%0A%20%20%20%20%20%20%20%20email%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D";
            request(constantes("URL_API")+graph, {
                method: "GET"
            }).then(response => {
                this.setState({
                    avwebinars: response.data.curso.avancecursos,
                    loading: false
                })
            })
        }
    }

    render() {
        return(
            <div>
                <Modal isOpen={this.state.modalOpen} toggle={this.toggle} className={"modal-lg"}>
                    <ModalHeader toggle={this.toggle}>
                        Participantes
                    </ModalHeader>

                    <ModalBody>
                        <ReactTable
                            data={this.state.avwebinars}                      
                            columns={[                      
                                {
                                    Header: "Nombre",
                                    accessor: "user",                 
                                    Cell: row => (
                                        <div className = "name">
                                        { row.value ? row.value.name : '' } 
                                        </div>
                                    )
                                },
                                {
                                    Header: "Apellidos",
                                    accessor: "user",                 
                                    Cell: row => (
                                        <div className = "surname">
                                        { row.value ? row.value.surname : '' } 
                                        </div>
                                    )
                                },
                                {
                                    Header: "Email",
                                    accessor: "user",                 
                                    Cell: row => (
                                        <div className = "email">
                                        { row.value ? row.value.email : ''} 
                                        </div>
                                    )
                                },
                                {
                                    Header: "Estado",
                                    accessor: "state",    
                                    show: !this.props.courseID?true:false,             
                                    Cell: row => (
                                        <div className = "state">
                                            {row.value === "pendiente"?
                                                <div onClick={() => this.remember(row)}>Recordar<i className="far fa-share-square"></i></div>
                                                :
                                                row.value === "aceptado"?"Aceptado":<div onClick={() => this.remember(row)}>Cancelado<i className="far fa-share-square"></i></div>}
                                        </div>
                                    )
                                }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                        />
                    </ModalBody>
                </Modal>
                <div  className="delete" onClick={this.showTable}>
                    {this.props.row.value&&this.props.row.value.length}
                    <i className="fas fa-users"></i>
                </div>
            </div>
        );    
    }
}