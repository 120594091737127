import React, {Component} from 'react';
import Logo from '../../components/Logo';
import UserView from '../../components/UserView';
import NavBar from '../../components/NavBar';
import './styles.css'

class Header extends Component {
    render() {
        return(
            <div className = "header">
                <div className = "header_content">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-lg-3">
                            <Logo/>
                            <UserView/>  
                        </div>
                        <div className="col-xs-12 col-sm-12 col-lg-9">
                            <NavBar/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
