import React, {Component} from 'react';
import request from '../../utils/request';
import './styles.css';
import ContentCard from '../../components/ContentCard';
import CommentCard from '../../components/CommentCard';
import CalendarioCard from '../../components/Calendario';
import constantes from '../../utils/constantes';

class Focus extends Component {
  
  constructor(props){
    var today =new Date();
    var tomorrow =new Date();
    var pasado =new Date();
    tomorrow.setDate(today.getDate()+1);
    pasado.setDate(today.getDate()+2);
    super(props);
    this.state = {
      entries: [],
      comments: [],
      entriesCalendario: [],
      day1: tomorrow.toISOString(),
      day2: pasado.toISOString(),
    };
    this.setAdmin = this.setAdmin.bind(this);
    this.setAdmin();
  }
  
  setAdmin(){
    let area = "focus";
    localStorage.setItem('admin', area);
  }
    
  componentDidMount(){
    
    request(constantes("URL_API") + "/entradas?_limit=0&area_admin=" + localStorage.getItem("admin"), { method: "GET" })
          .then( entradas => this.setState({
            entries: entradas
          }) ); //.then(entradas => console.log(entradas));
    request(constantes("URL_API") + "/exams", { method: "GET" })
          .then( exams => this.setState({
            exams: exams
          }) ); //.then(entradas => console.log(entradas));
    
    let consulta_ql = "/graphql?query=%0A%7B%0A%20%20comentarios(limit%3A0,%20where%3A%20%7B%20area_admin%3A%20%22" + localStorage.getItem("admin") + "%22%20%7D)%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%09comments%0A%20%20%20%20%20%20publish_date%0A%20%20%20%20%20%20visible%0A%20%20%20%20%20%20user%7B%0A%20%20%20%20%20%20%20%20username%0A%20%20%20%20%20%20%20%20picture%7B%0A%20%20%20%20%20%20%20%20%20%20%20url%0A%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20entrada%7B%0A%20%20%20%20%20%20%20%20title%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%7D%0A%20%20%20%0A";
    request(constantes("URL_API") + consulta_ql, { method: "GET" })
          .then( comentarios => this.setState({
            comments: comentarios.data.comentarios
          }) );//.then(comentarios => console.log(comentarios));
  }
  
  render() {

    
    return(
      <div className = "main_content">
        <div className = "row">
          
          <div className = "col-4 cont_card">
            <h2>THIS IS FOCUS</h2>
            { this.state.entries.sort(function(a, b) {
                var dateA = new Date(a.publish_date), dateB = new Date(b.publish_date);
                return dateB - dateA;}).filter((dato) => {return dato.borrador !== true }).filter((dato) => {return dato.publish_date < new Date().toISOString()}).slice(0,3).map((dato) => {
                return <ContentCard history={this.props.history} dato={dato} key={dato._id}/>
              })
            }
          </div>
          
          <div className = "col-4 cont_card">
            <h2>Últimos comentarios</h2>
            {
              this.state.comments.sort(function(a, b) {
                var dateA = new Date(a.publish_date), dateB = new Date(b.publish_date);
                return dateB - dateA;}).slice(0,3).map((dato) => {
                return <CommentCard history={this.props.history} dato={dato} key={dato._id}/>
              })
            }
          </div>
          <div className = "col-4 cont_card">
            <h2>Próximos Contenidos</h2>
            <div className="card shadow subtitle"><h3>Hoy</h3></div>
            {this.state.entries.filter((dato) => {return dato.publish_date > new Date().toISOString() && dato.publish_date < this.state.day1}).slice(0,2).map((dato) => {
                            
                      return <CalendarioCard history={this.props.history} dato={dato} key={dato._id}/>
                }
              )
            }
            <div className="card shadow subtitle"><h3>Mañana</h3></div>
            {this.state.entries.filter((dato) => {return dato.publish_date > this.state.day1 && dato.publish_date < this.state.day2}).slice(0,2).map((dato) => {

                      return <CalendarioCard history={this.props.history} dato={dato} key={dato._id}/>
                }
              )
            }
          </div>
        </div>
      </div>

    );
  }
}

export default Focus;