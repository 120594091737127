import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Input, Container, Col, Row, Alert } from 'reactstrap';
import ReactTable from "react-table";
import Dropzone from 'react-dropzone-uploader';
import Papa from 'papaparse';
import request from '../../utils/request';
import constantes from '../../utils/constantes';
import './styles.css';
import Loading from '../../components/Loading';


class ModalExamen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            examenfinal: false,
            data: [],
            dataManual: [],
            table: false,
            error: false,
            errorText: "",
            name: "",
            tabactivo: "manual",
            questions: [],
            loading: false
        };
    }

    deleteRow = (rowID) => {
        let newData = this.state.data.slice(0, rowID).concat(this.state.data.slice(rowID + 1, this.state.data.length));
        let newState = {data: newData};
        if(newData.length === 0){
            newState = {...newState, table: false, modal: false}
        }
        this.setState(newState);
    }

    cleanModal = () => {
        this.setState({
            modalOpen: false,
            examenfinal: false,
            data: [],
            dataManual: [],
            table: false,
            error: false,
            errorText: "",
            name: "",
            tabactivo: "manual",
            loading: false
        })
    }
    
    toggle = async () => {
       
       await request(constantes("URL_API") + "/exams/" + this.props.id, { method: "GET" })
       .then(response => {
           let examen = response
           this.setState({
               name: examen.name,
               examenfinal: examen.examenfinal,
               dataManual: examen.questions.map(a =>a._id)
           })
       })
        
        if(this.state.modalOpen === true){
            this.cleanModal()
        }else{
            this.setState({modalOpen: !this.state.modalOpen})
            this.manual();
        }
    }

    gestionaBloque = (id) => {
        let array = this.state.dataManual;
        // console.log(array)
        if (array.includes(id)){
            //eliminar elemento del array
            var index = array.indexOf(id);
            if (index > -1) {
                array.splice(index, 1);
            }
        } else {
            //Añadir al array
            array.push(id)
        };
        this.setState({selectBloque: array});
    }

    onChangeStatus = (data) => {
        this.setState({loading: true})
        if(data.meta.status === "done"){
            this.handleSubmit(data.file)
        }else if(data.meta.status === "removed"){
            this.setState({
                data: [],
                table: false,
                loading: false
            })
        }else if(data.meta.status === "rejected_file_type"){
            this.setState({
                error: true,
                errorText: "Error: Archivo no valido. Suba un .csv",
                loading: false
            })
        }
    }

    handleSubmit = (file) => {
        Papa.parse( file, {
            header: true,
            complete: results => {
                this.setState({
                    data: results.data,
                    table: true,
                    loading: false
                })
            }
        });
    }

    onDismiss = () => {
        this.setState({
            error: !this.state.error
        })
    }
    importar = () => {
        this.setState({
            tabactivo: "importar",
            dataManual: [],
            questions: []
        })
    }

    postQuestions = () => {
        console.log(this.state.data)
        const result = this.state.data&&this.state.data.length>0&&this.state.data.map(async dato => {

            if(dato.pregunta !== ""){
                let question = {
                    text: dato.pregunta,
                    type: "radio",
                    area_admin: "smartlearning"
                }

                let answers = []; 

                Object.keys(dato).forEach(item => {
                    if(item !== "pregunta"){
                        if(item === "1" || item === "2" || item === "3"){
                            answers = [...answers,{texto:dato[item],value:"false"}]
                        }
                    }
                })

                

                answers[dato.correcta-1] = {texto: answers[dato.correcta-1].texto, value:"true"}

                const resquestion = await request(constantes("URL_API")+"/questions", {
                    method: "POST",
                    body: {...question,answers}
                })

                return resquestion._id;
            }
        })

        return result;
    }

    editarExamen = () => {
        this.setState({loading: true});
        const tabactivo = this.state.tabactivo;
        let examen = {
            name: this.state.name,
            area_admin: "smartlearning",
            examenfinal: this.state.examenfinal,
            questionsManual: this.state.dataManual
        };

        if(tabactivo === "manual"){
            request(constantes("URL_API")+"/exams/"+this.props.id, {
                method: "PUT",
                body: {
                    name: examen.name,
                    area_admin: examen.area_admin,
                    examenfinal: examen.examenfinal,
                    questions: examen.questionsManual
                }
            }).then(response => {
                this.cleanModal();
                this.props.componentDidMount();
            })
        }else{
            Promise.all(this.postQuestions()).then((a)=>{       
                request(constantes("URL_API")+"/exams/"+this.props.id, {
                    method: "PUT",
                    body: {
                        name: examen.name,
                        area_admin: examen.area_admin,
                        examenfinal: examen.examenfinal,
                        questions: a
                    }
                }).then(response => {
                    this.cleanModal();
                    this.props.componentDidMount();
                })
            })
        }
    }
     manual = () => {
         this.setState({
             loading: true
         });
         request(constantes("URL_API")+"/questions", {
             method: "GET"
         }).then(response => {
            this.setState({
                tabactivo: "manual",
                questions: response,
                loading: false,
                table: false,
                data: []
            })
         })
     }
  render() {
    return (
        <div>
            <Loading loading={this.state.loading}/>
            <Modal isOpen={this.state.modalOpen} toggle={this.toggle} className={"modal-lg"}>
                <ModalHeader toggle={this.toggle}>
                    Nuevo Examen
                </ModalHeader>

                <ModalBody>
                    <Container>
                        {this.state.error&&<Alert color="danger" toggle={this.onDismiss}>
                            {this.state.errorText}
                        </Alert>}
                        <Row style={{marginBottom: "20px"}}>
                            <Col xs={"9"}>
                            <input
                                style={{width: "100%", height: 31, backgroundColor: "RGB(255, 255, 255)",
                                    borderColor: "RGB(204, 204, 204)",
                                    borderRadius: "4px",
                                    borderStyle: "solid",
                                    borderWidth: "1px"
                                }}
                                value={this.state.name}
                                onChange={name => this.setState({ name: name.target.value })} 
                                placeholder="Nombre Examen"/>
                            </Col>
                            <Col>
                                <div className="pretty p-switch p-fill" style={{marginTop: "10px"}}>
                                    <input name="examenfinal" id="examenfinal" onChange={(event) => this.setState({ examenfinal: event.target.checked })} type="checkbox" checked={this.state.examenfinal} />
                                    <div className="state">
                                        <label htmlFor="examenfinal"><em>Examen final</em></label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <div className="bottonera">
                                <div onClick={this.importar} className={this.state.tabactivo === "importar" ? "boton activo" : "boton"}>
                                    Importar
                                </div>
                                <div onClick={this.manual} className={this.state.tabactivo === "manual" ? "boton activo" : "boton"}>
                                    Manual
                                </div>
                            </div>
                        </Row>
                        <Row>
                        {this.state.tabactivo==="importar"?
                            <Dropzone
                                onChangeStatus={this.onChangeStatus}
                                maxFiles={1}
                                inputContent={"Adjunte su archivo .csv aquí..."}
                                multiple={false}
                                //accept={"text/csv"}
                            />:<ReactTable
                            style={{width: "100%"}}
                            data={this.state.questions}                      
                            columns={[
                                {
                                    Header: "",
                                    accessor: "_id",     
                                    width: 20,               
                                    Cell: row => (
                                        <div className = "name">
                                            <input name={row.value} id={row.value} type="checkbox" onChange={() => this.gestionaBloque(row.value)} checked={this.state.dataManual.includes(row.value)}/>
                                        </div>
                                    )
                                },                      
                                {
                                    Header: "Pregunta",
                                    accessor: "text",     
                                    width: 300,               
                                    Cell: row => (
                                        <div className = "name">
                                        { row.value } 
                                        </div>
                                    )
                                },
                                {
                                    Header: "Respuesta 1",
                                    accessor: "answers",                 
                                    Cell: row => (
                                        <div className = "name" style={{fontWeight: row.original[parseInt(row.original.correcta)] === row.value?"bold":"normal"}}>    
                                        { row.value[0].texto } 
                                        </div>
                                    )
                                },
                                {
                                    Header: "Respuesta 2",
                                    accessor: "answers",                 
                                    Cell: row => (
                                        <div className = "name" style={{fontWeight: row.original[parseInt(row.original.correcta)] === row.value?"bold":"normal"}}>    
                                        { row.value[1].texto } 
                                        </div>
                                    )
                                },
                                {
                                    Header: "Respuesta 3",
                                    accessor: "answers",                 
                                    Cell: row => (
                                        <div className = "name" style={{fontWeight: row.original[parseInt(row.original.correcta)] === row.value?"bold":"normal"}}>    
                                        { row.value[2].texto }
                                        </div>
                                    )
                                }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                        />}
                        </Row>
                        {this.state.table&&(
                            <Row>
                                <ReactTable
                                    width="100%"
                                    data={this.state.data}                      
                                    columns={[                      
                                        {
                                            Header: "Pregunta",
                                            accessor: "pregunta",     
                                            width: 190,               
                                            Cell: row => (
                                                <div className = "name">
                                                { row.value } 
                                                </div>
                                            )
                                        },
                                        {
                                            Header: "Respuesta 1",
                                            accessor: "1",                 
                                            Cell: row => (
                                                <div className = "name" style={{fontWeight: row.original[parseInt(row.original.correcta)] === row.value?"bold":"normal"}}>    
                                                { row.value } 
                                                </div>
                                            )
                                        },
                                        {
                                            Header: "Respuesta 2",
                                            accessor: "2",                 
                                            Cell: row => (
                                                <div className = "name" style={{fontWeight: row.original[parseInt(row.original.correcta)] === row.value?"bold":"normal"}}>    
                                                { row.value } 
                                                </div>
                                            )
                                        },
                                        {
                                            Header: "Respuesta 3",
                                            accessor: "3",                 
                                            Cell: row => (
                                                <div className = "name" style={{fontWeight: row.original[parseInt(row.original.correcta)] === row.value?"bold":"normal"}}>    
                                                { row.value } 
                                                </div>
                                            )
                                        },
                                        {
                                            Header: "",
                                            accessor: "3",                 
                                            width: 80,
                                            Cell: row => (
                                            <div className="delete">
                                                <span onClick={() => {
                                                if (window.confirm("¿Estás seguro que quieres borrar esto?")) {
                                                    this.deleteRow(row.index)
                                                }
                                                }
                                                } > <i className="fas fa-trash-alt"></i>
                                                </span>
                                            </div>
                                            )
                                        }
                                    ]}
                                    defaultPageSize={10}
                                    className="-striped -highlight"
                                />
                            </Row>
                        )}
                        <Row>
                        <Button style={{backgroundImage: "linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175))"}} onClick={this.editarExamen} >Guardar Cambios</Button>
                        </Row>
                    </Container>
                </ModalBody>
            </Modal>            
            <span onClick={this.toggle} style={{color:'black'}}><i className="fas fa-pencil-alt" /></span>
        </div>
    );
  }
}
export default ModalExamen;