import React from 'react';
import './styles.scss'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import ModalDesgloseTiendaMapa from "../../containers/SmartEstadistica/modalDesgloseTiendaMapa"


const BlockMap = withScriptjs(withGoogleMap(({ isMarkerShown, stores }) => {  
  
    return (

        <GoogleMap
            defaultZoom={6}
            defaultCenter={{ lat: 40.416775, lng: -3.703790 }}
        >
            {isMarkerShown && stores.map((e, i) => {
                
                let completado = ((e.users.map(user => {
                    let ultimoavance = [];
                   if(user.avancecursos.length!==0){     
                     ultimoavance =  user.avancecursos.sort(function (a, b) {
                        a = new Date(a.init_date);
                        b = new Date(b.init_date);
                        return a > b ? -1 : a < b ? 1 : 0;
                    }) [0]; 
                
                    return ultimoavance.avance.filter(b => b.status === 'completado').length === ultimoavance.avance.length ? 1 : 0}
                }).reduce((total, num) => {return total + num}, 0)) / e.users.length) * 100
            
                let cursor = 
                    completado >= 60 ? "https://api.cexsolvia.com/uploads/botongreen.png" :
                        (completado >= 30 ? "https://api.cexsolvia.com/uploads/botonnaranja.png" : "https://api.cexsolvia.com/uploads/botonred.png")
                return (<ModalDesgloseTiendaMapa tienda={e} kei={i} key={i} cursor={cursor}/>)
            })}
            
        </GoogleMap>

    );
}))

export default BlockMap;