import React from 'react';
import ReactTable from "react-table";
import './styles.css';
var moment = require('moment');

export default function ContenidosMasVistos (props){
    // console.log(props.dataContent)
    const tablaBonita = props.dataContent.map(cont => ({
        "Página": cont.titulo,
        // "Página": cont.pagina === "Post" ? "Post - " + cont.titulo : cont.pagina,
        "Visualizaciones": cont.visualizaciones.length,
        "Tiempo medio de visualización": Math.round((cont.visualizaciones.reduce((a, b) => ({ tiempo: a.tiempo + b.tiempo }), {tiempo: 0}).tiempo)/cont.visualizaciones.length * 100)/100 || 0,
        "fecha": cont.fecha
    }))
    // console.log(tablaBonita)
    return(
        <div>
           <ReactTable
                data={tablaBonita}
                columns={[
                    {
                    Header: "Contenidos",
                    accessor: "Página",
                    Cell: row => <div className="name">{row.value}</div>
                    },
                    {
                    Header: "Visualizaciones",
                    accessor: "Visualizaciones",
                    Cell: row => (
                        <div className="retos">{row.value}</div>
                    )
                    },
                    {
                        Header: "Tiempo medio",
                        accessor: "Tiempo medio de visualización",
                        Cell: row => (
                            <div className="retos">{row.value} s</div>
                        )
                        },
                        {
                            Header: "Fecha",
                            accessor: "fecha",
                            Cell: row => (
                                <div className="retos">{row.value==="-"?"-" : moment(row.value).format("DD/MM/YYYY")}</div>
                            )
                            },
                            {
                                Header: "Hora Publicación",
                                accessor: "fecha",
                                Cell: row => (
                                    <div className="retos">{row.value==="-"?"-" : moment(row.value).format("hh:mm:ss")}</div>
                                )
                                }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
                />
        </div>
    )
        
    
    
    }
    