import React, {Component} from 'react';
import { Button } from 'reactstrap';
// import ReactTable from "react-table";
import moment from 'moment';
import Loading from '../../components/Loading';
import constantes from '../../utils/constantes';
import ConvocatoriaReactTable from '../../components/ConvocatoriaReactTable';
import request from '../../utils/request';
import './styles.css';
import ModalConvocatorias from '../../components/ModalConvocatorias/ModalConvocatorias';

export default class Convocatoria extends Component{
    constructor(props) {
        super(props);
        this.state = {
            solviacanals: [],
            solviarols: [],
            selectedsolviarol: "",
            selectedsolviacanal: "",
            users: [],
            date: new Date(),
            modalOpen: false,
            name: "",
            borrador: false,
            desc: "",
            webinars: [],
            loading: false
        };
    }

    componentDidMount = () => {
        this.setState({
            loading: true
        })
        const query = "/graphql?query=%0A%7B%0A%20%20webinars(sort%3A%20%22date%3ADESC%22)%7B%0A%20%20%20%20_id%0A%20%20%20%20state%0A%20%20%20%20name%0A%20%20%20%20date%0A%20%20%20%20solviacanal%7B%0A%20%20%20%20%20%20name%0A%20%20%20%20%7D%0A%20%20%20%20solviarol%7B%0A%20%20%20%20%20%20name%0A%20%20%20%20%7D%0A%20%20%20%20userLeader%7B%0A%20%20%20%20%20%20username%0A%20%20%20%20%7D%0A%20%20%20%20avwebinars%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D";
        request(constantes("URL_API")+query, {
            method: "GET"
        }).then(response => {
            // console.log(response.data.webinars)
            this.setState({
                webinars: response.data.webinars,
                loading: false
            })
        });
    }

    toggle = () => {
        this.setState({
            modalOpen: !this.state.modalOpen
        })
    }

    render(){
        return(
            <div className = "main_content">
                <Loading loading={this.state.loading} />
                <ModalConvocatorias componentDidMount={this.componentDidMount} modalOpen={this.state.modalOpen} toogle={this.toggle}/>
                <div className="rounded_cont contents shadow">
                    <ConvocatoriaReactTable history={this.props.history} webinars={this.state.webinars} componentDidMount={this.componentDidMount}/>
                    <Button className="btn-gradient-style" onClick={this.toggle}>Nueva Convocatoria Webinars</Button>
                </div>
            </div>
        )
    }
    
}