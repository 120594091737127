import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReactTable from "react-table";
import './styles.css';
import Loading from '../../components/Loading';
import { Marker } from "react-google-maps"


class ModalDesgloseTiendaMapa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            loading: false,
            countAprobados: 0
        };
    }

    cleanModal = () => {
        this.setState({
            modalOpen: false,
            loading: false
        })
    }

    toggle = async () => {
        if (this.state.modalOpen === true) {
            this.cleanModal()
        } else {
            this.setState({ modalOpen: !this.state.modalOpen })
        }

        this.totalAprobados();
    }

    totalAprobados =  () =>{
        let counter = 0;
        let avancecursos = [];
         this.props.tienda.users.forEach(user => {

                avancecursos.push(...user.avancecursos)
        })

        avancecursos.forEach(av => {
            if(av.estao === "completado"){
                counter++;
            }
    })
        this.setState({
            countAprobados: counter
        })
    }


    render() {
        return (
            <div>
                <Loading loading={this.state.loading} />
                <Modal isOpen={this.state.modalOpen} toggle={this.toggle} className={"modal-lg"}>
                    <ModalHeader toggle={this.toggle}>
                        <h5>{this.props.tienda.name}</h5><h8>Aprobados totales Store: {this.state.countAprobados}</h8>
                    </ModalHeader>
                    <ModalBody>
                        <div>Total de usuarios en la tienda: {this.props.tienda.users.length}</div>
                        <ReactTable
                            data={this.props.tienda.users}
                            columns={[
                                {
                                    Header: 'Usuario',
                                    accessor: "email",
                                    headerClassName: "title",
                                    Cell: row => (
                                        <div className="title">
                                            {row.value}
                                        </div>
                                    )
                                },
                                {
                                    Header: 'Curso',
                                    accessor: "avancecursos",
                                    headerClassName: "nombre",
                                    Cell: row => (
                                        <div className="nombre">
                                            {
                                                row.value.length !== 0 &&
                                                row.value.sort(function (a, b) {
                                                a = new Date(a.init_date);
                                                b = new Date(b.init_date);
                                            })[0].cursos.nombre}
                                        </div>
                                    )
                                },
                                {
                                    Header: "Cantidad completada",
                                    accessor: "avancecursos",
                                    // width: 120,
                                    Cell: row => (
                                        <div className="type">
                                            <span>
                                                {
                                                    row.value.length !== 0 &&
                                                    (row.value.sort(function (a, b) {
                                                    a = new Date(a.init_date);
                                                    b = new Date(b.init_date);
                                                })[0].avance.filter(e => e.status === 'completado').length
                                                    /
                                                    row.value.sort(function (a, b) {
                                                        a = new Date(a.init_date);
                                                        b = new Date(b.init_date);
                                                    })[0].avance.length) * 100 + "%"}
                                            </span>
                                        </div>
                                    )
                                },
                                {
                                    Header: "Aprobado",
                                    accessor: "avancecursos",
                                    width: 80,
                                    Cell: row => (
                                        <div className="estado">
                                            {
                                                row.value.length !== 0 &&
                                                row.value.sort(function (a, b) {
                                                a = new Date(a.init_date);
                                                b = new Date(b.init_date);
                                            })[0].estao === "completado" ? "Si" : "No"}
                                        </div>
                                    )
                                },
                            ]}
                            defaultSorted={[
                                {
                                    id: "user.email",
                                    desc: true
                                }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                        />
                    </ModalBody>
                </Modal>
                <Marker
                    key={this.props.kei}
                    position={{ lat: this.props.tienda.latitud, lng: this.props.tienda.longitud }}
                    icon={{ url: this.props.cursor, anchor: { x: 12, y: 12 } }}
                    onClick={this.toggle}
                />
            </div>
        );
    }
}
export default ModalDesgloseTiendaMapa;