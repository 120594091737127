import React, { Component } from 'react';
import { Button, Input, Label, FormGroup } from 'reactstrap';
import request from '../../utils/request';
import constantes from '../../utils/constantes';

class CategoriaForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            nombre: this.props.categoria.nombre ? this.props.categoria.nombre : '',
            descripcion_corta: this.props.categoria.descripcion_corta ? this.props.categoria.descripcion_corta : '',
            categoria_padre: this.props.categoria.categoria_padre ? this.props.categoria.categoria_padre : '',
            active: this.props.categoria.active ? this.props.categoria.active : false,
            order: this.props.categoria ? this.props.categoria.order : false,
            photo: (this.props.categoria.image != null) ? this.props.categoria.image.url : './img/userplaceholder.jpg',
            image: (this.props.categoria.image != null) ? this.props.categoria.image.url : './img/userplaceholder.jpg',
            edit: this.props.categoria ? true : false,
            id_edit: this.props.categoria._id ? this.props.categoria._id : '',
        };
        this.printCategorias = this.printCategorias.bind(this);
        this.relatedCategory = this.relatedCategory.bind(this);
        this.handleNombre = this.handleNombre.bind(this);
        this.handleDescripcionCorta = this.handleDescripcionCorta.bind(this);
        this.crearCategoria = this.crearCategoria.bind(this);
        this.handleOrden = this.handleOrden.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleSelectParentCategory = this.handleSelectParentCategory.bind(this);        

    }

    handleSelectParentCategory(padre){
        this.setState({ 
            categoria_padre: {
                _id: padre._id,
                nombre: padre.nombre
            }  
        });
    }

    handleUpload(response){
        const imgToUpload = new FormData();
        imgToUpload.append('refId', response._id);//id del modelo
        imgToUpload.append('ref', "categorias" );//modelo strapi al que se va asociar
        imgToUpload.append('field', "image");//Campo del modelo (donde va la img)
        imgToUpload.append('files', this.state.image);

        request(constantes("URL_API") + "/upload", { method: 'POST', body: imgToUpload, }, false)
        .catch( err => console.log( err ) );
    }

    async crearCategoria(){

        let categoria = {
            nombre: this.state.nombre,
            descripcion_corta: this.state.descripcion_corta,
            categoria_padre: this.state.categoria_padre._id ? this.state.categoria_padre : null,
            order: this.state.order,
            active: this.state.active,
            area_admin:   localStorage.getItem("admin"),
        }
      
        var url;
        var metodo;
        if(this.state.edit === true){
            url = constantes("URL_API") + "/categorias/" + this.state.id_edit;
            metodo = "PUT";
        }else{
            url = constantes("URL_API") + "/categorias";
            metodo = "POST";
        }
    
        this.props.toggle();

        await request(url, {
            method: metodo,
            body: categoria
        })
        .then( response => {
            this.handleUpload(response);
            this.props.componentDidMount()
        })
        .catch( err => {
            console.log( err )
        });
        
    }
    
    handleSelect(event){

        event.persist();
        if(event.target.value !== ""){
            this.props.categorias.foreach((categoria) => { 
                if(categoria._id === event.target.value){
                    this.setState({ categoria_padre: { _id: categoria._id, nombre: categoria.nombre }})
                }
            });
        }else{ this.setState({ categoria_padre: '' }); }

    }

    handleNombre(value){
        this.setState({
            nombre: value.target.value
        });
    }

    handleActive(value){
        this.setState({
            active: value.target.checked
        });
    }

    handleDescripcionCorta(value){
        this.setState({
            descripcion_corta: value.target.value
        });
    }

    handleOrden(value){
        this.setState({
            order: value.target.value
        });
    }

    onImageChange(event) {
        // event.persist();
        if (event.target.files && event.target.files[0]) {
            this.setState({
                image: event.target.files[0]
            });
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({photo: e.target.result});
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    printCategorias(){
        return this.props.categorias.map((categoria, i) => {
            let returnValue = [];
            returnValue.push(
                    <option value={categoria._id} key={categoria._id}>
                        {categoria.nombre}
                    </option>
            );
            // returnValue.push(this.relatedCategory(categoria.categorias_hijas));
            return returnValue;
        });
    }

    relatedCategory(categorias_hijas){
        if(categorias_hijas.length > 0){    
            return categorias_hijas.map((categorias_hija) => {
                return(
                    <option value={categorias_hija._id} key={categorias_hija._id}>
                        &nbsp;&nbsp;&nbsp;{categorias_hija.nombre}
                    </option>
                );
            });
        }
    }

    render() {
        // console.log("la categoria padre ha vuelto así: " )
        // console.log(this.state.categoria_padre)
        return (


            <div className = "form-category modal-registry">             
                <div className="rounded_cont">
                <FormGroup>
                    <Label htmlFor = "titulo">Título de la categoría</Label>
                    <Input type="text" placeholder="Titulo" id="titulo" name="titulo" className="form-control" value={this.state.nombre} onChange={(event) => this.handleNombre(event)}/>
                    </FormGroup>
                </div>
                <div className="rounded_cont">
                    <Label htmlFor = "titulo">Descripción</Label>
                    <Input type="text" placeholder="Descripción" name="descripcion" className="form-control" value={this.state.descripcion_corta} onChange={(event) => this.handleDescripcionCorta(event)}/>
                </div>
                <div className="rounded_cont" style = {{ display: "none" }} >
                    <Input type="number" placeholder="Orden" name="orden" className="form-control" value={this.state.order} onChange={(event) => this.handleOrden(event)}/>
                </div>
                <div className = "row">
                    <div className = "row-6">
                        <div className="rounded_cont img">
                            <div className = "row">
                                <Label htmlFor = "form-categoria">Imagen destacada:</Label>
                            </div>
                            <div className = "row imagen">
                                <div className = "col-6">
                                    <img id="target" src={ this.state.photo } alt="Imagen Destacada" className="imageInput"/>
                                </div>
                                <div className = "col-6">
                                    <Input type="file" accept="image/*" onChange={this.onImageChange.bind(this)} className="form-control" id="group_image"/>
                                </div>
                            </div>
                        </div>                    
                    </div>
                    <div className = "col-6">
                        <div className="rounded_cont">
                            <Label htmlFor = "form-categoria">Categoría padre:</Label>
                            {/* <SelectParentCategory categoria = { this.props.categoria } categorias = { this.props.categorias } handleSelectParentCategory = { this.handleSelectParentCategory.bind(this) } ></SelectParentCategory> */}
                            <select className="form-control " id="form-categoria" value={this.state.categoria_padre._id} onChange={(event) => this.handleSelect(event)}>
                                <option value="" key={""}></option>
                                {
                                    this.printCategorias(this.props.categorias)
                                }
                            </select>
                        </div>                    
                    </div>
                </div>  
                
                    <div className = "row">
                        <div className = "col-2">
                        <div className="rounded_cont">
                            <Label htmlFor="active">¿Activo?</Label>
                            <Input type="checkbox" name="active" id="active" className="" checked={this.state.active} onChange={(event) => this.handleActive(event)}/>
{/*                                 
                            <div className="pretty p-switch p-fill">
                                <Input name="active" id="active" onChange={(event) => this.handleActive(event)} type="checkbox" checked={this.state.active}/>
                                <div className="state">
                                    <Label htmlFor = "create_notify">Activo</Label>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className = "row">
                    <div className = "col-8"></div>
                    <div className = "col-4">
                        <Button color="primary" onClick={() => this.crearCategoria()} > Guardar </Button>    
                    </div>
                </div>
                
            </div>
        );
    }
}

export default CategoriaForm;
