import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import ReactTable from "react-table";
import request from '../../utils/request';
import constantes from '../../utils/constantes';
import './styles.css';
import Loading from '../../components/Loading';


class ModalDesgloseCurso extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            loading: false
        };
    }

    cleanModal = () => {
        this.setState({
            modalOpen: false,
            loading: false
        })
    }

    toggle = async () => {
        console.log(this.props.curso.avancecursos)
        if (this.state.modalOpen === true) {
            this.cleanModal()
        } else {
            this.setState({ modalOpen: !this.state.modalOpen })
        }
    }


    render() {
        return (
            <div>
                <Loading loading={this.state.loading} />
                <Modal isOpen={this.state.modalOpen} toggle={this.toggle} className={"modal-lg"}>
                    <ModalHeader toggle={this.toggle}>
                        {this.props.curso.nombre}
                    </ModalHeader>                    
                    <ModalBody>
                    <ReactTable
                        data={this.props.curso.avancecursos}
                        columns={[
                            {
                                Header: 'Usuario',
                                accessor: "user.email",
                                headerClassName: "title",
                                Cell: row => (
                                    <div className="title">
                                        {row.value}
                                    </div>
                                )
                            },
                            {
                                Header: "Ratio de completado",
                                accessor: "avance",
                                width: 220,
                                Cell: row => (
                                    <div className="type">
                                        <span>
                                            {(row.value.filter(e => e.status === 'completado').length
                                                /
                                                row.value.length) * 100 + "%"}
                                        </span>
                                    </div>
                                )
                            },
                            {
                                Header: "Estado del curso",
                                accessor: "estao",
                                width: 220,
                                Cell: row => (
                                    <div className="estado">
                                        {row.value}
                                    </div>
                                )
                            },
                            {
                                Header: "Diploma",
                                accessor: "estao",
                                width: 220,
                                Cell: row => (
                                    <div className="estado">
                                        {row.value==='completado'?"Disponible":"No disponible"}
                                    </div>
                                )
                            }
                        ]}
                        defaultSorted={[
                            {
                                id: "user.email",
                                desc: true
                            }
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight"
                    />
                    </ModalBody>
                </Modal>
                <span onClick={this.toggle} style={{ color: 'black' }}><i className="fas fa-pencil-alt" /></span>
            </div>
        );
    }
}
export default ModalDesgloseCurso;