import React, { Component } from 'react';
import SmartEstadistica from "../SmartEstadistica";
import './styles.css';

class SmartLearning extends Component {
  
  constructor(props){
    super(props);
    let area = "smartlearning";
    localStorage.setItem('admin', area);
  }

  render(){
    return( <SmartEstadistica />);
  }

}
export default SmartLearning;