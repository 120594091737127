import React, {Component} from 'react';
import Select from 'react-select';
import './styles.css';
import SearchListServerSide from '../../utils/SearchListServerSide';
import request from '../../utils/request';
import constantes from '../../utils/constantes';
import { Button } from 'reactstrap';

class Notification extends Component {
    constructor(props){
        super(props);
        this.state = {
            title: "",
            body: "",
            users: [],                   
            date: "",
            allusers: [],
            selectedusers: [],
            toAll: false,
            area_admin: localStorage.getItem("admin")
        };
        this.handleChangeTitle = this.handleChangeTitle.bind(this); 
        this.handleChangeBody = this.handleChangeBody.bind(this); 
        this.handleChangeUser = this.handleChangeUser.bind(this); 
        this.sendNotification = this.sendNotification.bind(this); 
    }

    async sendNotification(){
        const finalNoti = {
            title: this.state.title,
            body: this.state.body,
            selectedusers: this.state.selectedusers
        };

        await finalNoti.selectedusers&&finalNoti.selectedusers.map(async(user) => {            
            let bodyN = {
                users: [user.value],
                title: finalNoti.title,
                body: finalNoti.body,
                date: new Date()

            }
            await request(constantes("URL_API")+"/mynotifications", {
                method: "POST",
                body: bodyN
            });           
        })

        this.setState({
            users: [],
            title: "",
            body: "",
            date: ""
        });
        alert("Notificaciones enviadas con exito");
    }

    handleChangeTitle(value){
        this.setState({ title: value.target.value});
    }

    handleChangeBody(value){
        this.setState({ body: value.target.value});
    }

    handleChangeUser = (selectedOption) => {
        this.setState({ selectedusers: selectedOption });
    }

    sendToAll = async() => {
        const finalNoti = {
            title: this.state.title,
            body: this.state.body,
            selectedusers: this.state.allusers
        };        
        let bodyZ = {
            users: finalNoti.selectedusers&&finalNoti.selectedusers.map(user =>user.value),
                    title: finalNoti.title,
                    body: finalNoti.body,
                    date: new Date()
        }
            await request(constantes("URL_API")+"/mynotifications", {
                method: "POST",
                body: 
                    bodyZ
                
            });           
    

        this.setState({
            users: [],
                    title: "",
                    body: "",
                    date: ""
        });
        alert("Notificaciones enviadas con exito");
    }

    async componentDidMount(){
        await SearchListServerSide("users", 0, 0, [], "", [], [{id:"token",value:"Token",compare:"containss"}])
        .then((users)=>{
            console.log(users)
            this.setState({
                allusers: users.map((user)=>{
                    return {
                        value: user._id,
                        label: user.username
                    }
                })
            })
        }); 
    }   
  
  render() {
      return(
        <div className = "main_content">

            <div className = "row">
                <div className = "col">
                    <h3>Nueva notificación</h3>
                </div>
            </div>

            <div className = "row">
                <div className = "col">
                    <div className = "row">
                        <div className = "col">
                            <div className = "literal">
                                Seleccione los destinatarios a los que se enviará la notificación a través de la APP. 
                            </div>
                        </div>
                    </div>                
                    <div className = "row">
                        <div className = "col">
                            <Select
                                isMulti
                                options={this.state.allusers}
                                className="form-control rounded-select rounded_cont shadow"
                                onChange={this.handleChangeUser}
                                value={this.state.selectedusers}
                                classNamePrefix="select"
                                isDisabled={this.state.toAll}
                            />
                        </div>
                        <div className = "col-3 rounded_cont">
                            <div className="pretty p-switch p-fill">
                                <input name="toAll" id="toAll" onChange={ (event) => this.setState({ toAll: event.target.checked, selectedusers: [] }) } type="checkbox" checked={ this.state.toAll } /> 
                                <div className="state">
                                    <label htmlFor = "toAll"><em>¿Enviar a todos?</em></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className = "row">
                        <div className = "col">
                            <div className = "rounded_cont shadow">
                                <input type="text" placeholder="Título" value={this.state.title} onChange={(event) => this.handleChangeTitle(event)} className="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div className = "row">
                        <div className = "col">
                            <div className = "rounded_cont shadow">
                                <textarea type="text" placeholder="Cuerpo del mensaje" value={this.state.body} onChange={(event) => this.handleChangeBody(event)} className="form-control notification"/>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>

            <div className = "row"> 
                {this.state.toAll?<div className="col-3">
                    <Button className="btn-gradient-style" onClick={() => this.sendToAll()} >Enviar a todos</Button>
                </div>:<div className="col-3">
                    <Button className="btn-gradient-style" onClick={() => this.sendNotification()} >Enviar Notificación</Button>
                </div>}
            </div>
        </div>
      );
  }
}

export default Notification;