import React, { Component } from 'react';
import { Container, Row, Alert, Button, Modal, ModalBody, ModalFooter, Tooltip} from 'reactstrap';
import ReactTable from "react-table";
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import Papa from 'papaparse';
import request from '../../utils/request';
import constantes from '../../utils/constantes';

export default class MasivoPreguntas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            data: [],
            table: false,
            tooltipOpen: false
        };
    }

    toggle = () => {
        let  obj = {};
        if(this.state.modal){
            obj = {...obj, data: [], table: false}
        }   
        this.setState({
            ...obj,
            modal: !this.state.modal
        })
    }

    toggletooltipOpen = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }    

    onDismiss = () => {
        this.setState({
            error: !this.state.error
        })
    }

    handleSubmit = (file) => {
        Papa.parse( file, {
            header: true,
            complete: results => {
                this.setState({
                    data: results.data,
                    table: true
                })
            }
        });
    }

    onChangeStatus = (data) => {
        if(data.meta.status === "done"){
            this.handleSubmit(data.file)
        }else if(data.meta.status === "removed"){
            this.setState({
                data: [],
                table: false
            })
        }else if(data.meta.status === "rejected_file_type"){
            this.setState({
                error: true,
                errorText: "Error: Archivo no valido. Suba un .csv"
            })
        }
    }

    importAll = () => {
        this.state.data&&this.state.data.length>0&&this.state.data.forEach(async dato => {
            let question = {
                text: dato.pregunta,
                type: "radio",
                area_admin: "smartlearning"
            }

            let answers = []; 

            Object.keys(dato).forEach(item => {
                if(item !== "pregunta"){
                    if(item === "1" || item === "2" || item === "3"){
                        answers = [...answers,{texto:dato[item],value:"false"}]
                    }
                }
            })

            

            answers[dato.correcta-1] = {texto: answers[dato.correcta-1].texto, value:"true"}

            // console.log({...question,answers})

            await request(constantes("URL_API")+"/questions", {
                method: "POST",
                body: {...question,answers}
            })
        })

        this.setState({
            modal: false,
            data: [],
            table: false
        })

        this.props.componentDidMount();
    }

    deleteRow = (rowID) => {
        let newData = this.state.data.slice(0, rowID).concat(this.state.data.slice(rowID + 1, this.state.data.length));
        let newState = {data: newData};
        if(newData.length === 0){
            newState = {...newState, table: false, modal: false}
        }
        this.setState(newState);
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={"modal-lg"}>
                    <ModalBody>
                        <Container>
                            {this.state.error&&<Alert color="danger" toggle={this.onDismiss}>
                                    {this.state.errorText}
                            </Alert>}
                            {/* <Row>
                                <div>
                                    <span id="DisabledAutoHideExample"> <i className="fas fa-trash-alt"></i> </span>
                                    <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} autohide={false} target="DisabledAutoHideExample" toggle={this.toggletooltipOpen}>
                                        <img src={ "https://training-solvia.s3.eu-west-1.amazonaws.com/36497187f3f449b9954e250822be86c9.jpg" } alt="Consejo"/>
                                    </Tooltip>
                                </div>
                            </Row> */}
                            <Row>
                                <Dropzone
                                    onChangeStatus={this.onChangeStatus}
                                    maxFiles={1}
                                    inputContent={"Adjunte su archivo .csv aquí..."}
                                    multiple={false}
                                    //accept={"text/csv"}
                                />
                            </Row>
                            {this.state.table&&(
                                <Row>
                                    <ReactTable
                                        width="100%"
                                        data={this.state.data}                      
                                        columns={[                      
                                            {
                                                Header: "Pregunta",
                                                accessor: "pregunta",     
                                                width: 190,               
                                                Cell: row => (
                                                    <div className = "name">
                                                    { row.value } 
                                                    </div>
                                                )
                                            },
                                            {
                                                Header: "Respuesta 1",
                                                accessor: "1",                 
                                                Cell: row => (
                                                    <div className = "name" style={{fontWeight: row.original[parseInt(row.original.correcta)] === row.value?"bold":"normal"}}>    
                                                    { row.value } 
                                                    </div>
                                                )
                                            },
                                            {
                                                Header: "Respuesta 2",
                                                accessor: "2",                 
                                                Cell: row => (
                                                    <div className = "name" style={{fontWeight: row.original[parseInt(row.original.correcta)] === row.value?"bold":"normal"}}>    
                                                    { row.value } 
                                                    </div>
                                                )
                                            },
                                            {
                                                Header: "Respuesta 3",
                                                accessor: "3",                 
                                                Cell: row => (
                                                    <div className = "name" style={{fontWeight: row.original[parseInt(row.original.correcta)] === row.value?"bold":"normal"}}>    
                                                    { row.value } 
                                                    </div>
                                                )
                                            },
                                            {
                                                Header: "",
                                                accessor: "3",                 
                                                width: 80,
                                                Cell: row => (
                                                <div className="delete">
                                                    <span onClick={() => {
                                                    if (window.confirm("¿Estás seguro que quieres borrar esto?")) {
                                                        this.deleteRow(row.index)
                                                    }
                                                    }
                                                    } > <i className="fas fa-trash-alt"></i>
                                                    </span>
                                                </div>
                                                )
                                            }
                                        ]}
                                        defaultPageSize={10}
                                        className="-striped -highlight"
                                    />
                                    <Button style={{backgroundImage: "linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175))"}} onClick={()=>this.importAll()} >Importar todo</Button>
                                </Row>
                            )}
                        </Container>
                    </ModalBody>
                    <ModalFooter>                
                    </ModalFooter>
                </Modal>    
                <Button style={{backgroundImage: "linear-gradient(rgb(13, 229, 245), rgb(9, 148, 212), rgb(6, 99, 175))"}} onClick={()=>this.toggle()} >Importación Masiva</Button>
            </div>
        );
    }
}