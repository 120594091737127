/* Fichero de constantes de la aplicación */ 


const constantes = (param) => {

    const URL_APP_DEV = "https://s3-eu-west-1.amazonaws.com/adminseal/recovery/index.html";
    const URL_APP_PRE = "http://adminseal-dev.s3-website-eu-west-1.amazonaws.com";
    const URL_APP_PRO = "https://sealadmin.hub7s.com";

    const URL_API_DEV = "http://localhost:1338";
    const URL_API_PRE = "http://52.50.65.127:1337";
    const URL_API_NEW_PRE = "http://34.251.228.205:1338";
    const URL_API_PRO = "https://apiseal.hub7s.com";

    // API DE FOCUS
    //const URL_API_PRO = "https://api.cexsolvia.com";

    const area_class = [];
    area_class["content"] = "fa-file-alt";
    area_class["training"] = "fa-sitemap";
    area_class["focus"] = "fa-tachometer-alt";
    area_class["smartlearning"] = "fa-graduation-cap";

    const menu_elem = [];
    menu_elem["content"] = {'Inicio': 'content', 'Nuevo Contenido': 'new', 'Contenidos': 'contents','Analítica': 'contentanalytics', 'Comentarios': 'comments', 'Medios': 'media',
    //  'Usuarios': 'users',
    'Categorias':'categorias', 'Notificaciones':'notification'};
    menu_elem["training"] = {'Métricas': 'training', 'Logros': 'logros', 'Retos': 'retos', 'Itinerarios':'itinerarios' };
    menu_elem["training"] = {'Inicio' : 'metricas', 'Retos': 'retos', 'Itinerarios':'itinerarios', 'Usuarios': 'users'  };
    menu_elem["focus"] = {
        // 'Inicio': 'focus', 'Usuarios': 'users' 
    };
    
    menu_elem["smartlearning"] = { 
        // 'Usuarios': 'users' ,
        'Analítica':'analytics',
        'Cursos': 'listadocursos', 
        // 'Medios': 'media', 
        'Notificaciones':'notification'
    };

    const menu_elem_contenidos = [];
    menu_elem_contenidos["smartlearning"] = {
        'Contenidos': 'contents',
        'Nuevo Contenido': 'new' ,
        'Exámenes':'exam', 
        'Preguntas': 'questions',   
        'Webinars': 'convocatoria'
    }

    let data = {

        area_class: area_class,
        menu_elem: menu_elem,
        menu_elem_contenidos,

        // Fix
        URL_API_PRO: URL_API_PRO,
        URL_API_DEV: URL_API_DEV,
        URL_API_PRE: URL_API_PRE,

        // Current active URLs
        URL_APP: URL_APP_DEV,
        URL_API: URL_API_PRO
        ///as

    }        
    return data[param];
}

export default constantes;