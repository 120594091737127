import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import ReactTable from "react-table";
import Loading from '../../components/Loading';
import request from '../../utils/request';
import './styles.css';
import constantes from '../../utils/constantes';


class RoleManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: '',
      idEditando: '',
      roles: [],
      rolTitle: '',
      modal: false,
      selectBloque: [],
      loading: false,
      start: 0,
      limit: 8, 
      activePage: 1,
      totalCount: 0,

    };
    
  }
  limpiezaEstados = () => {
    this.setState({
      editId: '',
      idEditando: '',
      rolTitle: '',
      modal: false,
      selectBloque: [],
      selectedRol: [],
      //roles: [],
    })
  }
  filtraRoles=(value) =>{
    if (value.target.value !== "" || value.target.value !== undefined) {
      let dataFiltro = this.state.roles2.filter(array => array.name.includes(value.target.value))
      this.setState({ roles: dataFiltro })
    } else {
      this.setState({ roles: this.state.roles2 })
    }
  }
  setLoading = (loading) => {
    this.setState({
      loading
    });
  }
  getData = (rol) => {
    if (rol) {
      request(constantes("URL_API") + "/solviarol", { method: "GET" })
        .then((count) => {
          this.setState({
            totalCount: count.length
          })
        });
    }
    var consulta = "/solviarol/?_limit=" + this.state.limit + "&_start=" + this.state.start + "&_sort=name:ASC";

    request(constantes("URL_API") + consulta, { method: "GET" })
      .then((rolName) => {
        this.setState({
          roleName: rolName
        })
      });

    request(constantes("URL_API") + "/solviarol/", { method: "GET" })
      .then((rolName) => {
        this.setState({
          rolNameAll: rolName
        })
      });
  }
  toggle = (id = "") => {
    //si está abierto lo cerramos
    if (this.state.modal === true) {
      this.limpiezaEstados();
      this.setState({
        modal: !this.state.modal

      });
    } else {
      //compruebo que tenga ID válido
      if (id !== "") {
        //Si tiene ID, cargamos la data del ID y abrimos.
        this.setState({ loading: true })
        request(constantes("URL_API") + "/solviarol/" + id, { method: "GET" })
          .then((roles) => this.setState({
            rolTitle: roles.name,
            idEditando: id,
            modal: !this.state.modal,
            loading: false,
          }))

      } else {
        //Si no tiene ID, abrimos el modal
        this.setState({
          modal: !this.state.modal
        });
      }
    }
  }

  gestionaBloque = (id) => {
    let array = this.state.selectBloque;
    if (array.includes(id)) {
      //eliminar elemento del array
      var index = array.indexOf(id);
      if (index > -1) {
        array.splice(index, 1);
      }

    } else {
      //Añadir al array
      array.push(id)
    };
    this.setState({ selectBloque: array });
  }

  editRol = (id) => {
    let body = {
      name: this.state.rolTitle,
    }
    request(constantes("URL_API") + "/solviarol/" + id, {
      method: "PUT",
      body: body,
    }).then(response => {
      this.limpiezaEstados();
      this.componentDidMount();
    }).catch(err => console.log(err))
  }

  addRol = () => {
    let body = {
      name: this.state.rolTitle,
      area_admin: localStorage.getItem('admin')
    }
    request(constantes("URL_API") + "/solviarol", {
      method: "POST",
      body: body,
    }).then(response => {
      this.limpiezaEstados();
      this.componentDidMount();
    })
  }

  deleteRol = (id) => {
    request(constantes("URL_API") + "/solviarol/" + id, { method: "DELETE" })
      .then((response) => this.componentDidMount());
  }
  handleNew = (value, concepto) => {
    let estado = "rol" + concepto;
    this.setState({
      [estado]: value.target.value
    });
  }
  handlePageChange = (pageNumber) => {
    this.setState({
      activePage: pageNumber,
      start: (pageNumber - 1) * this.state.limit
    });
  }
 
  componentDidUpdate(prevProps, prevState) {
    if (this.state.activePage !== prevState.activePage) {
      this.getData(true);
      this.setState({ start: 0 });
      // this.setState({ start: 0, activePage: 1 });
    }
    if (this.state.rolNamePrevios !== prevState.rolNamePrevios) {
      //convertir state rolesPrevios en rolesElectos y rolesElectosId
      this.state.rolNamePrevios.forEach((element) => {
        let electos = this.state.rolNameElectos
        let electosId = this.state.rolNameElectosId
        let id = element.id
        let rol = this.state.rolNameAll.filter(function (e) {
          return e.id === id
        })
        electos.push(rol[0])
        electosId.push(id);
        this.setState({
          rolNameElectos: electos,
          rolNameElectosId: electosId,
          selectedRol: []
        });
      })
    }
  }
  componentDidMount() {
    this.getData(true);
    this.setState({ loading: true })
    var consulta = "/solviarol";
    request(constantes("URL_API") + consulta, { method: "GET" })
      .then((rol) => this.setState({
        roles: rol,
        roles2: rol,
      }, () => { this.setState({ loading: false }) }));
  }


  render() {

    return (

      <div className="main_content listado">
        <div><h3>Roles</h3></div>
        <div className="rounded_cont contents shadow">
          <div className="row">

            <div className="col">
              <Loading loading={this.state.loading} />
              <input type="text" placeholder="Nombre de rol" onChange={this.filtraRoles} className="form-control list-search" />
              <ReactTable
                data={this.state.roles}
                columns={[
                  {
                    Header: '',
                    accessor: "_id",
                    width: 40,
                    Cell: row => (
                      <div className=""><input name={row.value} id={row.value} type="checkbox" onChange={() => this.gestionaBloque(row.value)} checked={this.state.selectBloque.includes(row.value) ? true : false} />
                        <div className="state"></div></div>
                    )
                  },
                  {
                    Header: "Nombre de rol",
                    accessor: "name",
                    //width: 120,                          
                    Cell: row => (
                      <div className="name">
                        {row.value}
                      </div>
                    )
                  },
                  {
                    Header: "Nº de usuarios",
                    accessor: "users",
                    width: 120,
                    headerClassName: 'roles',
                    Cell: row => (
                      <div className="roles">
                        {row.value.length}
                      </div>
                    )
                  },
                  {
                    Header: "Editar",
                    accessor: "_id",
                    width: 80,
                    Cell: row => (
                      <div className="edit">
                        <span onClick={() => { this.toggle(row.value) }}><i className="fas fa-pencil-alt" /></span>

                      </div>
                    )
                  },
                  {
                    Header: "Eliminar",
                    accessor: "_id",
                    width: 80,
                    Cell: row => (
                      <div className="delete">
                        <span onClick={() => {
                          if (window.confirm("¿Estás seguro que quieres eliminar este rol?")) {
                            this.deleteRol(row.value)
                          }
                        }
                        } > <i className="fas fa-trash-alt"></i>
                        </span>
                      </div>
                    )
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
            {this.state.selectBloque.length > 0 ?
              <div className="col-12">
                <div className="row menuBloque">
                  <div className="col-10"> <span >Has seleccionado {this.state.selectBloque.length} {this.state.selectBloque.length < 2 ? "rol" : "roles"}.</span>
                  </div>
                  <div className="col-2 text-center"> <span onClick={() => {
                    if (window.confirm("¿Estás seguro que quieres eliminar estos roles?")) {
                      this.state.selectBloque.forEach((element) => this.deleteRole(element))
                    };
                    this.setState({ selectBloque: [] });
                  }
                  } > <i className="fas fa-trash-alt"></i>
                  </span>
                  </div>
                </div>
              </div> : ""}
          </div>
        </div>

        <div className="row">
          <div className="col-3">
            <Button color="primary" onClick={() => this.toggle()} >Añadir Rol</Button>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className + ('marcomodal')}>
          <ModalBody className="registroUser">
            <div className="row">
              <div className="col">
                <Form>
                  <div className="row">
                    <div className="col">
                      <FormGroup>
                        <Label for="title">Nombre del rol</Label>
                        <Input type="text" name="title" id="title" placeholder="Nombre de rol" value={this.state.rolTitle} onChange={(value) => this.handleNew(value, "Title")} />
                      </FormGroup>
                    </div>
                  </div>
                </Form>
              </div>
            </div>

          </ModalBody>
          <ModalFooter>
            <Button color="primary"
              onClick={this.state.idEditando === '' ? this.addRol : () => { this.editRol(this.state.idEditando) }}
              disabled={this.state.rolTitle === "" ||
                this.state.rolNameElectosId === []
                ? true : false}
            >{this.state.idEditando === '' ? 'Añadir Rol' : 'Guardar cambios'}</Button>
            <Button color="secondary" onClick={this.toggle}>Descartar</Button>
          </ModalFooter>
        </Modal>
        
      </div>
    );
  }
}
export default RoleManager;