import React, { Component } from 'react';
import { Button, Alert, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormText, Row } from 'reactstrap';
import request from '../../utils/request';
import constantes from '../../utils/constantes';
import ReactTable from "react-table";
import Papa from 'papaparse';



class MassiveUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      photo: "/img/userplaceholder.jpg",
      newUsers: [],
      repeatedUsers: [],
      message:'',
      zonasApp: {content: true, training:false,farming:false,focus:false,smartlearning:false}
    };
  }
  limpiezaEstados = () => {
    this.setState({      
      photo: "/img/userplaceholder.jpg",
      newUsers: [],
      repeatedUsers: [],
      zonasApp: {content: true, training:false,farming:false,focus:false,smartlearning:false}
    });
  };

  workCSV = data => {
    Papa.parse(data.target.files[0], {
      delimiter: ";",
      header: true,
      skipEmptyLines: true,
      complete: async results => {
        await this.setState({ csv: results });
        let newUsers = [];
        let repeatedUsers = [];
        this.state.csv.data.map(e => {
          this.props.usuarios.some(i => i.email === e.email) === true
            ? repeatedUsers.push(e)
            : newUsers.push(e);
        });
        let emailRepetidos = repeatedUsers.map(e => e.email);
        console.log("usuarios nuevos: " + newUsers.length);
        console.log("usuarios repetidos: " + emailRepetidos.length);
        this.setState({
          newUsers,
          repeatedUsers: this.props.usuarios.filter(e =>
            emailRepetidos.includes(e.email)
          ),
          datosEdit: repeatedUsers
        });
      }
    });
  };
  inactivaUsers = () => {
    this.setState({ message: '' });
    let user = {
      status: false
    };
    this.state.repeatedUsers.map(e => {
      request(constantes("URL_API") + "/users/" + e._id, {
        method: "PUT",
        body: user
      })
        .then(async response => {
          await this.props.reassignCourses(response._id);
          let m1 = this.state.message;
          let m2 = m1 + "Se ha dado de baja a " + response.email + ". ";
          this.setState({ message: m2 });
          this.limpiezaEstados();
          this.props.fatherDidMount();
        })
    });
  };

  toggle = () => {
    //si está abierto lo cerramos
    if (this.state.modal === true) {
      this.limpiezaEstados();
      this.setState({
        message: '',
        modal: !this.state.modal
      });
    } else {
      this.setState({
        modal: !this.state.modal
      });
    }
  };

  addUser = () => {
    this.setState({ message: '' });    
    this.state.newUsers.filter(
        user =>
          user.email !== "" &&
          user.name !== "" &&
          this.props.roles.find(e => e.name === user.rol) !==
            undefined &&
          this.props.canales.find(e => e.name === user.canal) !==
            undefined
      ).map(async (usuario)=>{
        let coordenadas = await fetch(
          "https://maps.googleapis.com/maps/api/geocode/json?address=" +
            encodeURI(usuario.ciudad?usuario.ciudad:"Alicante" + usuario.cp?usuario.cp:"03001") +
            "&key=AIzaSyC6Umm0WckCCFOWjff7TLvU4UHmhnxqd7I"
        );
        let coordenadasjson = await coordenadas.json();        
          // console.log(coordenadasjson.results);
          let res_lng = coordenadasjson.results.length > 0? await coordenadasjson.results[0].geometry.location.lng:1;
          let res_lat = coordenadasjson.results.length > 0? await coordenadasjson.results[0].geometry.location.lat:1;

        let user = {
          date: new Date(),
          email: usuario.email,
          name: usuario.name,
          password: "123456",
          role: this.props.idRol,
          solviaRol: this.props.roles.find(e => e.name === usuario.rol)._id,
          solviaCanal: this.props.canales.find(e => e.name === usuario.canal)._id,
          status: true,
          surname: usuario.surname ? usuario.surname : "",
          username: usuario.email,
          zonas_admin: {content: false, training: false, focus: false, smartlearning: false},
          zonas_app: this.state.zonasApp,
          cp: usuario.cp?usuario.cp:"03001",
          ciudad: usuario.ciudad?usuario.ciudad:"Alicante",
          lat: res_lat,
          lng: res_lng,
          store: this.props.stores.find(e => e.name === usuario.store)!==undefined?this.props.stores.find(e => e.name === usuario.store)._id:null
          
        }  
        console.log(user)      
        request(constantes("URL_API") + "/users", {
          method: "POST",
          body: user
        })
          .then(async response => {
            console.log(response)
            await this.props.reassignCourses(response._id);
            let m1 = this.state.message;
          let m2 = m1 + "Se ha dado de alta a " + response.email + ". ";
          this.setState({ message: m2 });
            this.limpiezaEstados();
            this.props.fatherDidMount();
          }).catch(err =>
            console.log(
              (err)
            )
          );
      }

      )
    
    
    
  };

  render() {
    return (
      <div>
        <Button
          className="btn-gradient-style"
          onClick={() => {
            this.toggle();
          }}
        >
          Cargar CSV
        </Button>

        {/* modal para add/edicion  */}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className + "marcomodal"}
        >
          <ModalBody className="modal-registry shadow">
            <div className="row">
              <div className="col">
                {/* <div className="btn-gradient-style"> */}
                <label color="success" htmlFor="upload_file">
                  Cargar CSV
                </label>
                <input
                  type="file"
                  id="upload_file"
                  className="form-control"
                  onChange={value => this.workCSV(value)}
                />
                {(this.state.newUsers.length > 0 ||
                  this.state.repeatedUsers.length > 0) && (
                  <div>
                    <h3>Nuevos usuarios</h3>
                    <ReactTable
                      data={this.state.newUsers}
                      columns={[
                        {
                          Header: "Apto",
                          accessor: "email",
                          headerClassName: "email",
                          Cell: row => (
                            <div className="email">
                              <span>
                                {row.original.email !== "" &&
                                row.original.name !== "" &&
                                this.props.roles.find(
                                  e => e.name === row.original.rol
                                ) !== undefined &&
                                this.props.canales.find(
                                  e => e.name === row.original.canal
                                ) !== undefined
                                  ? "apto"
                                  : "no apto"}{" "}
                              </span>
                            </div>
                          )
                        },
                        {
                          Header: "Email",
                          accessor: "email",
                          headerClassName: "email",
                          Cell: row => (
                            <div className="email">
                              <span>{row.value} </span>
                            </div>
                          )
                        },
                        {
                          Header: "Nombre",
                          accessor: "name",
                          headerClassName: "name",
                          Cell: row => (
                            <div className="email">
                              <span>{row.value} </span>
                            </div>
                          )
                        },
                        {
                          Header: "Apellidos",
                          accessor: "surname",
                          headerClassName: "surname",
                          Cell: row => (
                            <div className="surname">
                              <span>{row.value} </span>
                            </div>
                          )
                        },
                        {
                          Header: "Rol",
                          accessor: "rol",
                          headerClassName: "rol",
                          Cell: row => (
                            <div className="rol">
                              <span>{row.value} </span>
                            </div>
                          )
                        },
                        {
                          Header: "Canal",
                          accessor: "canal",
                          headerClassName: "canal",
                          Cell: row => (
                            <div className="canal">
                              <span>{row.value} </span>
                            </div>
                          )
                        },
                        {
                          Header: "Store",
                          accessor: "store",
                          headerClassName: "store",
                          Cell: row => (
                            <div className="store">
                              <span
                              >{this.props.stores.find(e => e.name === row.value)!==undefined?row.value:null} </span>
                            </div>
                          )
                        },
                        {
                          Header: "CP",
                          accessor: "cp",
                          headerClassName: "cp",
                          Cell: row => (
                            <div className="cp">
                              <span>{row.value} </span>
                            </div>
                          )
                        },
                        {
                          Header: "Ciudad",
                          accessor: "ciudad",
                          headerClassName: "ciudad",
                          Cell: row => (
                            <div className="ciudad">
                              <span>{row.value} </span>
                            </div>
                          )
                        }
                      ]}
                      defaultPageSize={10}
                      className="-striped -highlight"
                    />
                    <h3>Usuarios ya existentes</h3>
                    <ReactTable
                      data={this.state.repeatedUsers}
                      columns={[
                        {
                          Header: "Email",
                          accessor: "email",
                          headerClassName: "email",
                          Cell: row => (
                            <div className="email">
                              <span>{row.value} </span>
                            </div>
                          )
                        },
                        {
                          Header: "Nombre",
                          accessor: "name",
                          headerClassName: "name",
                          getProps: (state, rowInfo, column) => {
                            return {
                              style: {
                                background:
                                  rowInfo &&
                                  this.state.datosEdit.find(
                                    e => e.email === rowInfo.row.email
                                  ).name !== rowInfo.row.name
                                    ? "red"
                                    : null
                              }
                            };
                          },
                          Cell: row => (
                            <div className="email">
                              <span
                              >
                                {this.state.datosEdit.find(
                                  e => e.email === row.original.email
                                ).name !== row.value
                                  ? this.state.datosEdit.find(
                                      e => e.email === row.original.email
                                    ).name
                                  : row.value}{" "}
                              </span>
                            </div>
                          )
                        },
                        {
                          Header: "Apellidos",
                          accessor: "surname",
                          headerClassName: "surname",
                          getProps: (state, rowInfo, column) => {
                            return {
                              style: {
                                background:
                                  rowInfo &&
                                  this.state.datosEdit.find(
                                    e => e.email === rowInfo.row.email
                                  ).surname !== JSON.stringify(rowInfo.row.surname)
                                    ? "red"
                                    : null
                              }
                            };
                          },
                          Cell: row => (
                            <div className="surname">
                              <span
                              >
                                {this.state.datosEdit.find(
                                  e => e.email === row.original.email
                                ).surname !== row.value
                                  ? this.state.datosEdit.find(
                                      e => e.email === row.original.email
                                    ).surname
                                  : row.value}{" "}
                              </span>
                            </div>
                          )
                        },
                        // {
                        //   Header: "Rol",
                        //   accessor: "solviaRol.name",
                        //   headerClassName: "rol",
                        //   getProps: (state, rowInfo, column) => {
                        //     return {
                        //         style: {
                        //             background: rowInfo && this.state.datosEdit.find(e =>e.email === rowInfo.row.email).rol!==rowInfo.row.solviaRol.name ? 'red' : null,
                        //         },
                        //     };
                        //   },
                        //   Cell: row => (
                        //     <div className="rol">
                        //       <span>{this.state.datosEdit.find(e =>e.email === row.original.email).rol!==row.value?this.state.datosEdit.find(e =>e.email === row.original.email).rol:row.value}  </span>
                        //     </div>
                        //   )
                        // },
                        // {
                        //   Header: "Canal",
                        //   accessor: "solviaCanal.name",
                        //   headerClassName: "canal",
                        //   getProps: (state, rowInfo, column) => {
                        //     return {
                        //         style: {
                        //             background: rowInfo && this.state.datosEdit.find(e =>e.email === rowInfo.row.email).canal!==rowInfo.row.solviaCanal.name ? 'red' : null,
                        //         },
                        //     };
                        //   },
                        //   Cell: row => (
                        //     <div className="canal">
                        //       <span>{this.state.datosEdit.find(e =>e.email === row.original.email).canal!==row.value?this.state.datosEdit.find(e =>e.email === row.original.email).canal:row.value}  </span>
                        //     </div>
                        //   )
                        // },
                        {
                          Header: "Store",
                          accessor: "store",
                          headerClassName: "store",
                          getProps: (state, rowInfo, column) => {
                            return {
                              style: {
                                background:
                                  rowInfo &&
                                  this.state.datosEdit.find(
                                    e => e.email === rowInfo.row.email
                                  ).store !== JSON.stringify(rowInfo.row.store)
                                    ? "red"
                                    : null
                              }
                            };
                          },
                          Cell: row => (
                            <div className="store">
                              <span
                              onClick={()=>{
                                console.log(this.state.datosEdit.find(
                                  e => e.email === row.original.email
                                ).store)
                                console.log(row.value)
                                console.log(row.original)
                                console.log(row.value===this.state.datosEdit.find(
                                  e => e.email === row.original.email
                                ).store)
                                }}
                              >
                                {this.state.datosEdit.find(
                                  e => e.email === row.original.email
                                ).store !== row.value
                                  ? this.state.datosEdit.find(
                                      e => e.email === row.original.email
                                    ).store
                                  : row.value}{" "}
                              </span>
                            </div>
                          )
                        },
                        {
                          Header: "CP",
                          accessor: "cp",
                          headerClassName: "cp",
                          getProps: (state, rowInfo, column) => {
                            return {
                              style: {
                                background:
                                  rowInfo &&
                                  this.state.datosEdit.find(
                                    e => e.email === rowInfo.row.email
                                  ).cp !== JSON.stringify(rowInfo.row.cp)
                                    ? "red"
                                    : null
                              }
                            };
                          },
                          Cell: row => (
                            <div className="cp">
                              <span
                                onClick={() => {
                                  console.log(
                                    typeof
                                      this.state.datosEdit.find(
                                        e => e.email === row.original.email
                                      ).cp
                                  );
                                  console.log(typeof row.value);
                                  console.log(this.state.datosEdit.find(
                                    e => e.email === row.original.email
                                  ).cp + " " + row.value);
                                  console.log(
                                    this.state.datosEdit.find(
                                      e => e.email === row.original.email
                                    ).cp !== JSON.stringify(row.value)
                                  )
                                  console.log(12345!==12345);
                                  console.log("12345"!=="12345");
                                }}
                              >
                                {this.state.datosEdit.find(
                                  e => e.email === row.original.email
                                ).cp !== row.value
                                  ? this.state.datosEdit.find(
                                      e => e.email === row.original.email
                                    ).cp
                                  : row.value}{" "}
                              </span>
                            </div>
                          )
                        },
                        {
                          Header: "Ciudad",
                          accessor: "ciudad",
                          headerClassName: "ciudad",
                          getProps: (state, rowInfo, column) => {
                            return {
                              style: {
                                background:
                                  rowInfo &&
                                  this.state.datosEdit.find(
                                    e => e.email === rowInfo.row.email
                                  ).ciudad !== JSON.stringify(rowInfo.row.ciudad)
                                    ? "red"
                                    : null
                              }
                            };
                          },
                          Cell: row => (
                            <div className="ciudad">
                              <span>{row.value} </span>
                            </div>
                          )
                        }
                      ]}
                      defaultPageSize={10}
                      className="-striped -highlight"
                    />
                    <h3>Acceso Apps</h3>
                    <Row>
                      <label htmlFor="ad_training">Training</label>
                      <input
                        id="ad_training"
                        type="checkbox"
                        onChange={() =>
                          this.setState({
                            zonasApp: {
                              ...this.state.zonasApp,
                              training: !this.state.zonasApp.training
                            }
                          })
                        }
                        checked={
                          this.state.zonasApp.training === true
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="ad_farming">Farming</label>
                      <input
                        id="ad_farming"
                        type="checkbox"
                        onChange={() =>
                          this.setState({
                            zonasApp: {
                              ...this.state.zonasApp,
                              farming: !this.state.zonasApp.farming
                            }
                          })
                        }
                        checked={
                          this.state.zonasApp.farming === true
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="ad_smartlearning">
                        SmartLearning
                      </label>
                      <input
                        id="ad_smartlearning"
                        type="checkbox"
                        onChange={() =>
                          this.setState({
                            zonasApp: {
                              ...this.state.zonasApp,
                              smartlearning: !this.state.zonasApp
                                .smartlearning
                            }
                          })
                        }
                        checked={
                          this.state.zonasApp.smartlearning === true
                            ? true
                            : false
                        }
                      />
                    </Row>
                  </div>
                )}
                <span>{this.state.message}</span>
                {/* </div> */}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-gradient-style"
              onClick={() => this.addUser()}
            >
              Añadir usuarios
            </Button>
            <Button
              className="btn-gradient-style"
              onClick={() => this.inactivaUsers()}
            >
              Baja de usuarios
            </Button>
            <Button color="secondary" onClick={this.toggle}>
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default MassiveUsers;