import React, {Component} from 'react';
import './styles.css';
import VideoApp from '../../ChatApp/App/App';
import OpenTokVideo from './OpenTokVideo';
import CompartirDocumentos from '../../components/CompartirDocumentos';
// import ChatComponent from '../../components/ChatComponent';

import auth from '../../utils/auth';
import NewChatComponent from '../../components/ChatComponent/NewChatComponent';
// import VideoComponent from '../../components/VideoComponent/VideoComponent';

export default class Convocatoria extends Component{
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id
        };
    }

    render(){
        const userID = auth.getUserInfo();
        const user = {
            username: userID.username,
            _id: userID._id
        }
        return(
            <div>
                <OpenTokVideo webinarID={this.state.id} user={user}/>
                <CompartirDocumentos webinarID={this.state.id}/>
                {/* <ChatComponent webinarID={this.state.id}/> */}
                <NewChatComponent webinar={this.state.id}/>
            </div>
        )
    }
    
}