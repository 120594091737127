import React, { Component } from 'react';
import ReactTable from "react-table";
import './styles.css';
import "react-table/react-table.css";
import ModalDesgloseCurso from "./modalDesgloseCurso"

class TablaCursos extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
      }


  render() {
    return (
              <ReactTable                
                data={this.props.avancecursos.map(u => {
                  u.participantes = u.avancecursos.length;
                  u.rCompletados = u.avancecursos.filter(a => a.avance.filter(e =>e.status==='completado')).length
                  ===0?"No hay datos":(Math.round(((u.avancecursos.filter(a => a.avance.filter(e =>e.status==='completado').length===a.avance.length).length
                  /
                u.avancecursos.length)*100)*100)/100+ "%");
                u.rAprobados = u.avancecursos.filter(a => a.avance.filter(e =>e.status==='completado')).length
                ===0?"No hay datos":(Math.round(((u.avancecursos.filter(a => a.estao==="completado").length
                /
              u.avancecursos.length)*100)*100)/100 + "%")
                  return u})}
                columns={[
                  {
                    Header: 'Curso',
                    accessor: "nombre",
                    headerClassName: "title",
                    Cell: row => (
                      <div className="title">
                        {row.value}
                      </div>
                    )
                  },
                  {
                    Header: "Participantes",
                    accessor: "participantes",
                    width: 120,
                    Cell: row => (
                      <div className="published_date">
                        {row.value}
                      </div>
                    )
                  },
                  {
                    Header: "Ratio de completado",
                    accessor: "rCompletados",
                    width: 220,
                    Cell: row => (
                      <div className="type">
                        <span>
                        {row.value}
                      </span>
                      </div>
                    )
                  },                 
                  {
                    Header: "Ratio de aprobados",
                    accessor: "rAprobados",
                    width: 220,
                    Cell: row => (
                      <div className="estado">
                        {row.value}
                      </div>
                    )
                  },
                  {
                    Header: "Desglose",
                    accessor: "avancecursos",
                    width: 120,
                    Cell: row => (
                      <div className="published_date">
                      <ModalDesgloseCurso curso={row.original}/>                        
                      </div>
                    )
                  },
                  // {
                  //   Header: "Apto",
                  //   accessor: "estao",
                  //   width: 230,
                  //   Cell: row => (
                  //     <div className="destacada">
                  //       {row.value!=="completado"?"No disponible":"Disponible"}
                  //     </div>
                  //   )
                  // }
                ]}
                defaultSorted={[
                  {
                    id: "publish_date",
                    desc: true
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
    )
            }
}

export default TablaCursos;