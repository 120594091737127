import React, {Component} from 'react';
import renderHTML from 'react-render-html';
import TimeContentCard from '../TimeContentCard/';
import { Link } from 'react-router-dom';
import './styles.css';

class ContentCard extends Component {
    
    render() {       
        return (
            
            <div className = "card shadow" id={"entry" + this.props.dato._id } >
                <div className = "row">
                    <div className="col justify-content-center imageCard">
                        <a href={'./edit/'+this.props.dato._id}>
                            <img src= { ((this.props.dato.image != null) ? this.props.dato.image.url : "./img/userplaceholder.jpg") } alt=""/>
                        </a>
                    </div>
                </div>
                <div className = "row">                    
                    <div className = "col">
                        <div className = "row">
                            <div className = "col card-title">
                                <a href={'./edit/'+this.props.dato._id}>{renderHTML(this.props.dato.title)}</a>
                            </div>
                        </div>
                        <div className = "row">
                            <div className = "col-5 mr-0 pr-0 when">
                                <TimeContentCard time={this.props.dato.publish_date}/>
                            </div>    
                            <div className = "col-7 mr-0 other-meta">
                                <span>Comentarios: {this.props.dato.comentarios.length} {/*| Visitas: {this.props.dato.visitas}*/}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col ml-0 mr-0 pl-0 pr-0 buttons">                                
                                <Link to ={'./edit/'+this.props.dato._id}>Editar</Link>                                
                                {/*<a href={'/view/'+this.props.dato._id} className = "publish">Publicar ya</a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        )
    }
}

export default ContentCard;