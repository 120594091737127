import React, {Component} from 'react';
import renderHTML from 'react-render-html';
import TimeContentCard from '../TimeContentCard/';
import constantes from '../../utils/constantes';
import request from '../../utils/request';
import { Link } from 'react-router-dom';

import './styles.css';

class CalendarioCard extends Component {
    publica(){

        request(constantes("URL_API") + "/entradas/"+this.props.dato._id, {

            method: "PUT",
            body: {
                publish_date:new Date()
            }
          })
          .then( () => {
             this.setState({publicado: !this.state.publicado})     
          })
          .then( () => {
            this.setState({publicafecha:new Date()})     
         })
          .catch( err => {
            console.log( err )
          });

    }
    
    constructor(props){
        super(props);
        
        this.state = {

            publicado: true,
            publicafecha: new Date()
        };
        
    }
    componentDidMount(){
        this.setState({publicafecha:this.props.dato.publish_date})
        this.setState({publicado:true})
    }
    
    render() {       
        return (
            
            <div className = "card" id={"entry" + this.props.dato._id } >
                <div className = "row">
                    <div className="col justify-content-center imageCard">
                        <Link to={'/edit/'+this.props.dato._id}>
                            <img src={(this.props.dato.image != null) ? this.props.dato.image.url : "/img/userplaceholder.jpg"} alt=""/>
                        </Link>
                    </div>
                </div>
                <div className = "row">                    
                    <div className = "col">
                        <div className = "row">
                            <div className = "col card-title">
                                <Link to ={'/edit/'+this.props.dato._id}>{renderHTML(this.props.dato.title)}</Link>
                            </div>
                        </div>
                        <div className = "row">
                            <div className = "col-5 mr-0 pr-0 when">
                                <TimeContentCard time={this.state.publicafecha}/>
                            </div>    
                            <div className = "col-7 mr-0 other-meta">
                            <span>Comentarios: {this.props.dato.comentarios.length}</span>
                            {/* <span>Comentarios: {this.props.dato.comentarios.length} | Visitas: {this.props.dato.visitas}</span> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col ml-0 mr-0 pl-0 pr-0 buttons">                                
                                <Link to={'/edit/'+this.props.dato._id}>Editar</Link>
                                {this.state.publicado===true?<a className = "publish" onClick={() =>this.publica()}>Publicar ya</a>:<a className = "publish">Publicado</a>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        )
    }
}

export default CalendarioCard;