import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import ReactTable from "react-table";
import Loading from '../../components/Loading';
import request from '../../utils/request';
import './styles.css';
import constantes from '../../utils/constantes';
import Pagination from "react-js-pagination";


class Itinerarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: '',
      idAvatar: '',
      idEditando: '',
      itinerarios: [],
      itinerarioTitle: '',
      itinerarioIcon: '',
      itinerarioGroup: '',
      itinerarioOrder: '',
      modal: false,
      modalGaleria: false,
      modalEditItinerario: false,
      newDescription: '',
      newAvatar: '',
      selectBloque: [],
      selectedReto: [],
      retos: [],
      retosElectos: [],
      retosElectosId: [],
      retosPrevios: [],
      photo: '/img/userplaceholder.jpg',
      loading: false,
      start: 0,
      limit: 8,
      activePage: 1,
      totalCount: 0,

    };
    this.deleteItinerario = this.deleteItinerario.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleGaleria = this.toggleGaleria.bind(this);
    this.handleNew = this.handleNew.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.storeAvatar = this.storeAvatar.bind(this);
    this.storechangeAvatar = this.storechangeAvatar.bind(this);
    this.handleNewavatar = this.handleNewavatar.bind(this);
    this.handlechangeAvatar = this.handlechangeAvatar.bind(this);
    this.addItinerario = this.addItinerario.bind(this);
    this.printRetosElectos = this.printRetosElectos.bind(this);
    this.gestionaBloque = this.gestionaBloque.bind(this);
    this.editItinerario = this.editItinerario.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.seleccionaReto = this.seleccionaReto.bind(this);
    this.filtraItinerarios = this.filtraItinerarios.bind(this);
    this.handleNewRecordatorios = this.handleNewRecordatorios.bind(this);
    this.limpiezaEstados = this.limpiezaEstados.bind(this);
    this.confirmaReto = this.confirmaReto.bind(this);
    this.quitaReto = this.quitaReto.bind(this);
  }
  limpiezaEstados() {
    this.setState({
      editId: '',
      idAvatar: '',
      idEditando: '',
      itinerarioTitle: '',
      itinerarioIcon: '',
      itinerarioGroup: '',
      itinerarioOrder: '',
      modal: false,
      modalGaleria: false,
      modalEditItinerario: false,
      newDescription: '',
      newAvatar: '',
      selectBloque: [],
      selectedReto: [],
      retos: [],
      retosElectos: [],
      retosElectosId: [],
      photo: '/img/userplaceholder.jpg',
    })
  }
  quitaReto(reto) {
    let array = this.state.retosElectos.filter(function (el) {
      return el.id !== reto.id
    });
    let idarray = this.state.retosElectosId.filter(function (el) {
      return el !== reto.id
    })
    this.setState({
      retosElectos: array,
      retosElectosId: idarray
    })
  }
  printGalery() {
    return this.state.retos.map((reto) => {
      return (
        <div className="col-3 retocont" key={reto._id}>
          <div id={reto._id} onClick={() => this.seleccionaReto(reto)}>
            <div className="imageInput" style={{ backgroundImage: `url('${(reto.picture !== null && reto.picture !== undefined) ? reto.picture.url : "/img/userplaceholder.jpg"}')` }} >
              <span className="reto-title galeria">
                {reto.name}
              </span>
            </div>
            {(this.state.selectedReto.includes(reto.id) ? <div className='color-selected-overlay reto' /> : "")}
          </div>
        </div>
      );
    });
  }
  printRetosElectos() {
    return this.state.retosElectos.map((reto) => {
      return (
        <div className="col-2 retoitinerario" key={reto._id}>
          <div id={reto._id} style={{ backgroundImage: `url('${(reto.picture !== null && reto.picture !== undefined) ? reto.picture.url : "/img/userplaceholder.jpg"}')` }} className="">
            <span className="quitareto" onClick={() => this.quitaReto(reto)} >X</span>
            <div className="reto-title">
              {reto.name}
            </div>
          </div>
        </div>
      );
    });
  }
  confirmaReto() {
    if (this.state.retosElectosId.includes(this.state.selectedReto[0])) {
      this.setState({
        modalGaleria: !this.state.modalGaleria,
        selectedReto: []
      });
    } else {
      let electos = this.state.retosElectos
      let electosId = this.state.retosElectosId
      let id = this.state.selectedReto[0]
      let reto = this.state.retosAll.filter(function (e) {
        return e.id === id
      })
      electos.push(reto[0])
      electosId.push(id);
      this.setState({
        modalGaleria: !this.state.modalGaleria,
        retosElectos: electos,
        retosElectosId: electosId,
        selectedReto: []
      });
    }
  }
  filtraItinerarios(value) {
    if (value.target.value !== "" || value.target.value !== undefined) {
      let dataFiltro = this.state.itinerarios2.filter(array => array.name.includes(value.target.value))
      this.setState({ itinerarios: dataFiltro })
    } else {
      this.setState({ itinerarios: this.state.itinerarios2 })
    }
  }
  setLoading(loading) {
    this.setState({
      loading
    });
  }
  getData(bol) {
    if (bol) {
      request(constantes("URL_API") + "/retos", { method: "GET" })
        .then((count) => {
          this.setState({
            totalCount: count.length
          })
        }).catch(err => {
          localStorage.clear();
          this.props.history.push('/auth/login');
        });
    }
    var consulta = "/retos/?_limit=" + this.state.limit + "&_start=" + this.state.start + "&_sort=name:ASC";

    request(constantes("URL_API") + consulta, { method: "GET" })
      .then((retos) => {
        this.setState({
          retos: retos
        })
      }).catch(err => {
        localStorage.clear();
        this.props.history.push('/auth/login');
      });
    request(constantes("URL_API") + "/retos/", { method: "GET" })
      .then((retos) => {
        this.setState({
          retosAll: retos
        })
      }).catch(err => {
        localStorage.clear();
        this.props.history.push('/auth/login');
      });
  }
  seleccionaReto(reto) {
    let selectedReto = [];
    selectedReto.push(reto.id);
    this.setState({
      selectedReto: selectedReto,
      selectedRetoId: selectedReto[0]._id
    });
  }

  actualizaItinerarios(id) {
    const itinerariosMod = [...this.state.itinerarios]; //copia el estado
    itinerariosMod.find(e => e._id === id).status = !(itinerariosMod.find(e => e._id === id).status);
    this.setState({ itinerarios: itinerariosMod });
  }
  toggleGaleria() {
    this.getData();
    this.setState({
      modalGaleria: !this.state.modalGaleria
    });
  }
  toggle(id = "") {
    //si está abierto lo cerramos
    if (this.state.modal === true) {
      this.limpiezaEstados();
      this.setState({
        modal: !this.state.modal

      });
    } else {
      //compruebo que tenga ID válido
      if (id !== "") {
        //Si tiene ID, cargamos la data del ID y abrimos.
        this.setState({ loading: true })
        request(constantes("URL_API") + "/itinerarios/" + id, { method: "GET" })
          .then((itinerarios) => this.setState({
            editItinerarioData: itinerarios,
            itinerarioTitle: itinerarios.name,
            itinerarioIcon: itinerarios.icon,
            itinerarioGroup: itinerarios.group ? itinerarios.group : '',
            itinerarioOrder: itinerarios.order,
            idEditando: id,
            photo: itinerarios.picture !== null ? itinerarios.picture.url : '/img/userplaceholder.jpg',
            retosPrevios: itinerarios.retos,
            modal: !this.state.modal,
            loading: false,
          }))

      } else {
        //Si no tiene ID, abrimos el modal
        this.setState({
          modal: !this.state.modal
        });
      }
    }
  }

  gestionaBloque = (id) => {
    let array = this.state.selectBloque;
    if (array.includes(id)) {
      //eliminar elemento del array
      var index = array.indexOf(id);
      if (index > -1) {
        array.splice(index, 1);
      }

    } else {
      //Añadir al array
      array.push(id)
    };
    this.setState({ selectBloque: array });
  }

  editItinerario(id) {
    let body = {
      name: this.state.itinerarioTitle,
      icon: this.state.itinerarioIcon,
      group: this.state.itinerarioGroup,
      order: this.state.itinerarioOrder,
      retos: this.state.retosElectosId,
    }
    request(constantes("URL_API") + "/itinerarios/" + id, {
      method: "PUT",
      body: body,
    }).then(response => {
      this.handlechangeAvatar("itinerario", response._id);
      this.limpiezaEstados();
      this.componentDidMount();
    }).catch(err => console.log(err))
  }

  addItinerario() {

    let body = {
      name: this.state.itinerarioTitle,
      icon: this.state.itinerarioIcon,
      group: this.state.itinerarioGroup,
      order: this.state.itinerarioOrder,
      retos: this.state.retosElectosId,
    }
    //console.log(body)
    request(constantes("URL_API") + "/itinerarios", {
      method: "POST",
      body: body,


    }).then(response => {
      this.handleNewavatar("itinerarios", response._id);
      this.limpiezaEstados();
      this.componentDidMount();
    })
  }



  deleteItinerario(id) {
    request(constantes("URL_API") + "/itinerarios/" + id, { method: "DELETE" })
      .then((response) => this.componentDidMount()).catch(err => console.log(err));
  }
  handleNewRecordatorios(value, position) {
    let array = this.state.newRecordatorios;
    let objeto = {};
    objeto.texto = value.target.value;
    objeto.offset = 0;
    objeto.tipo = "";
    array[position] = objeto;
    this.setState({
      newRecordatorios: array
    });

  }
  handleNew(value, concepto) {
    let estado = "itinerario" + concepto;
    this.setState({
      [estado]: value.target.value
    });
  }
  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,
      start: (pageNumber - 1) * this.state.limit
    });
  }
  storeAvatar(value) {
    if (value.target.files && value.target.files[0]) {
      this.setState({
        idAvatar: value.target.files[0]
      });
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ photo: e.target.result });
      };
      reader.readAsDataURL(value.target.files[0]);
    }
  }
  storechangeAvatar(value) {
    if (value.target.files && value.target.files[0]) {
      this.setState({
        idAvatar: value.target.files[0]
      }, () => {
        this.handlechangeAvatar(this.state.idEditando);
      });

    }
  }
  handleNewavatar = async (ref, refId) => {
    const imgToUpload = new FormData();
    imgToUpload.append('files', this.state.idAvatar);
    imgToUpload.append('field', "picture");
    imgToUpload.append('refId', refId);
    imgToUpload.append('ref', ref);
    request(constantes("URL_API") + "/upload", {
      method: 'POST',
      body: imgToUpload,
    }, false)

      .catch(err => console.log(err));
  }
  handlechangeAvatar = async () => {
    const imgToUpload = new FormData();
    imgToUpload.append('files', this.state.idAvatar);
    imgToUpload.append('field', "picture");
    imgToUpload.append('refId', this.state.idEditando);
    imgToUpload.append('ref', "itinerario");
    request(constantes("URL_API") + "/upload", {
      method: 'POST',
      body: imgToUpload,
    }, false)
      .catch(err => console.log(err));
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.activePage !== prevState.activePage) {
      this.getData(true);
      this.setState({ start: 0 });
      // this.setState({ start: 0, activePage: 1 });
    }
    if (this.state.retosPrevios !== prevState.retosPrevios) {
      //convertir state retosPrevios en retosElectos y retosElectosId
      this.state.retosPrevios.forEach((element) => {
        let electos = this.state.retosElectos
        let electosId = this.state.retosElectosId
        let id = element.id
        let reto = this.state.retosAll.filter(function (e) {
          return e.id === id
        })
        electos.push(reto[0])
        electosId.push(id);
        this.setState({
          retosElectos: electos,
          retosElectosId: electosId,
          selectedReto: []
        });
      })
    }
  }
  componentDidMount() {
    this.getData(true);
    this.setState({ loading: true })
    var consulta = "/graphql?query=%7B%0A%20itinerarios(sort%3A%22name%22,%20limit%3A%200)%7B%0A%20%20%20%20picture%7B%0A%20%20%20%20%20%20url%0A%20%20%20%20%7D%0A%20%20%20%20_id%0A%20%20%20%20name%20%20%20%20%0A%20%20%20%20description%20%0A%20%20%09grupo%0A%20%20%09icon%0A%20%20%09order%0A%20%20%09retos%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20name%20%20%20%20%20%20%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D%0A";
    request(constantes("URL_API") + consulta, { method: "GET" })
      .then((itinerarios) =>{
        if(Object.keys(itinerarios).includes("errors")){
          localStorage.clear();
          this.props.history.push('/auth/login');
        }else{
          this.setState({
            itinerarios: itinerarios.data.itinerarios,
            itinerarios2: itinerarios.data.itinerarios,
            }, () => { this.setState({ loading: false }) })
      }         
    });
    request(constantes("URL_API") + "/graphql?query=%7B%0Aitinerarios%7B%0A%20%20name%0A%09_id%0A%20%20%7D%0A%7D", { method: "GET" })
      .then((itinerarios) => {
        if(Object.keys(itinerarios).includes("errors")){
          localStorage.clear();
          this.props.history.push('/auth/login');
        }else{
          this.setState({
            itinerarios: itinerarios.data.itinerarios
          })
      }
    });


  }


  render() {

    return (

      <div className="main_content listado">
        <div><h3>Itinerarios</h3></div>
        <div className="rounded_cont contents shadow">
          <div className="row">

            <div className="col">
              <Loading loading={this.state.loading} />
              <input type="text" placeholder="Nombre de Itinerario" onChange={this.filtraItinerarios} className="form-control list-search" />
              <ReactTable
                data={this.state.itinerarios}
                columns={[
                  {
                    Header: '',
                    accessor: "_id",
                    width: 40,
                    Cell: row => (
                      <div className=""><input name={row.value} id={row.value} type="checkbox" onChange={() => this.gestionaBloque(row.value)} checked={this.state.selectBloque.includes(row.value) ? true : false} />
                        <div className="state"></div></div>
                    )
                  },
                  {
                    Header: "Nombre de Itinerario",
                    accessor: "name",
                    //width: 120,                          
                    Cell: row => (
                      <div className="name">
                        {row.value}
                      </div>
                    )
                  },
                  {
                    Header: "Nº de contenidos",
                    accessor: "retos.length",
                    width: 80,
                    headerClassName: 'retos',
                    Cell: row => (
                      <div className="retos">
                        {row.value}
                      </div>
                    )
                  },
                  {
                    Header: "Editar",
                    accessor: "_id",
                    width: 80,
                    Cell: row => (
                      <div className="edit">
                        <span onClick={() => { this.toggle(row.value) }}><i className="fas fa-pencil-alt" /></span>

                      </div>
                    )
                  },
                  {
                    Header: "Eliminar",
                    accessor: "_id",
                    width: 80,
                    Cell: row => (
                      <div className="delete">
                        <span onClick={() => {
                          if (window.confirm("¿Estás seguro que quieres eliminar este itinerario?")) {
                            this.deleteItinerario(row.value)
                          }
                        }
                        } > <i className="fas fa-trash-alt"></i>
                        </span>
                      </div>
                    )
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
            {this.state.selectBloque.length > 0 ?
              <div className="col-12">
                <div className="row menuBloque">
                  <div className="col-10"> <span >Has seleccionado {this.state.selectBloque.length} {this.state.selectBloque.length < 2 ? "itinerario" : "itinerarios"}.</span>
                  </div>
                  <div className="col-2 text-center"> <span onClick={() => {
                    if (window.confirm("¿Estás seguro que quieres eliminar estos itinerarios?")) {
                      this.state.selectBloque.forEach((element) => this.deleteItinerario(element))
                    };
                    this.setState({ selectBloque: [] });
                  }
                  } > <i className="fas fa-trash-alt"></i>
                  </span>
                  </div>
                </div>
              </div> : ""}
          </div>
        </div>

        <div className="row">
          <div className="col-3">
            <Button color="primary" onClick={() => this.toggle()} >Añadir Itinerario</Button>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className + ('marcomodal')}>
          <ModalBody className="registroUser">
            <div className="row">
              <div className="col">
                <Form>
                  <div className="row">
                    <div className="col">
                      <FormGroup>
                        <Label for="title">Título de Itinerario</Label>
                        <Input type="text" name="title" id="title" placeholder="Título de itinerario" value={this.state.itinerarioTitle} onChange={(value) => this.handleNew(value, "Title")} />
                      </FormGroup>
                      <FormGroup>
                        <Label for="icon">Icono</Label>
                        <Input type="text" name="icon" id="icon" placeholder="Icono" value={this.state.itinerarioIcon} onChange={(value) => this.handleNew(value, "Icon")} />
                      </FormGroup>
                      <FormGroup>
                        <Label for="group">Grupo</Label>
                        <Input type="text" name="group" id="group" placeholder="Grupo" value={this.state.itinerarioGroup} onChange={(value) => this.handleNew(value, "Group")} />
                      </FormGroup>
                      <FormGroup>
                        <Label for="order">Orden</Label>
                        <Input type="number" name="order" id="order" placeholder="Orden" value={this.state.itinerarioOrder} onChange={(value) => this.handleNew(value, "Order")} />
                      </FormGroup>
                    </div>
                    <div className="col-5">
                      <FormGroup>
                        <Label htmlFor="avatar">Imagen del Itinerario</Label>
                        <div className="row">
                          <div><img id="target" src={this.state.photo} alt="Imagen Destacada" className="imageInput" /></div>
                          <Input className="col-12 inputAvatar" type="file" name="file" id="avatar" accept=".jpg,.png, .gif" onChange={this.storeAvatar} />
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Label htmlFor="avatar">Retos seleccionados</Label>
                    </div>
                  </div>
                  <div className="row">
                    {this.printRetosElectos()}
                    <div className="col-6 retoitinerario añade" key="añade">
                      <Button id="añade" onClick={this.toggleGaleria} clasName="primary">Añadir</Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>

          </ModalBody>
          <ModalFooter>
            <Button color="primary"
              onClick={this.state.idEditando === '' ? this.addItinerario : () => { this.editItinerario(this.state.idEditando) }}
              disabled={this.state.itinerarioTitle === "" ||
                this.state.itinerarioIcon === "" ||
                this.state.itinerarioOrder === "" ||
                this.state.retosElectosId === []
                ? true : false}
            >{this.state.idEditando === '' ? 'Añadir Itinerario' : 'Guardar cambios'}</Button>
            <Button color="secondary" onClick={this.toggle}>Descartar</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalGaleria} toggle={this.toggleGaleria} className="container-fluid galeria">
          <ModalHeader className="modalHeader" toggle={this.toggleGaleria}>Elije un reto</ModalHeader>
          <Loading loading={this.state.loading} />
          <ModalBody className="text-center row">
            {this.printGalery()}
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.limit}
              totalItemsCount={this.state.totalCount}
              onChange={(pageNumber) => this.handlePageChange(pageNumber)}
            />
          </ModalBody>
          <ModalFooter>
            <Button className="col" color="primary" onClick={this.confirmaReto}>Guardar</Button>{' '}
            <Button className="col" color="secondary" onClick={this.toggleGaleria}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default Itinerarios;