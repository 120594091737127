import React, {Component} from 'react';
import './styles.css';
import TimeContentCard from '../TimeContentCard/';
import request from '../../utils/request';
import constantes from '../../utils/constantes';

class CommentCard extends Component {
    visibiliza(){

        request(constantes("URL_API") + "/comentarios/"+this.props.dato._id, {

            method: "PUT",
            body: {
                visible:!this.state.toggleOjo
            }
          })
          .then( () => {
             this.setState({toggleOjo: !this.state.toggleOjo})     
          })
          .catch( err => {
            console.log( err )
          });

    }
    
    constructor(props){
        super(props);
        
        this.state = {

            toggleOjo: false
        };
        
    }
    componentDidMount(){
        this.setState({toggleOjo:this.props.dato.visible})
    }
    render() {
        // console.log(this.props);
        return (
            <div className ="card shadow comment" id={this.props.dato._id}>
                <div className = "author">
                    {this.props.dato.user===null?<div className = "img"><img src="/img/userplaceholder.jpg" alt="usuario eliminado"/></div>:<div className = "img">
                      <img src={ this.props.dato.user.picture !== null ? this.props.dato.user.picture.url : "/img/userplaceholder.jpg" } alt={ this.props.dato.user.username }/>
                    </div>}
                    <span>Por {this.props.dato.user===null?<i>usuario eliminado</i>:this.props.dato.user.username }</span> 
                    <div className = "comment">
                       { this.props.dato.comments ? this.props.dato.comments.substr(0,200) : "" }
                    </div>
                </div>
                <div>
                    <div>
                        <div className="comment_date"><hr></hr><TimeContentCard time={this.props.dato.publish_date}/></div>
                    </div>
                    <div className = "row">
                        <div className="col card-title published">
                            {/* { console.log(this.props) } */}
                            <span>Publicado en {this.props.dato.entrada ? this.props.dato.entrada.title : ""}</span>
                            {/* <span>Publicado en </span><a href={'/view/'+this.props.dato.entrada._id}>{this.props.dato.entrada.title}</a> */}
                        </div>
                    </div>
                    <div className="ojoOculto">
                        {this.state.toggleOjo===true?<span className="badge bg-orange" onClick={() =>this.visibiliza()}>Visibilidad: <img src="/img/ico/icon_show.png" alt=""/></span>:
                        <span className="badge bg-orange" onClick={() => this.visibiliza()}>Visibilidad: <img src="/img/ico/icon_noshow.png" alt=""/></span>}
                    </div>
                </div>
            </div>
        
        )   
    }
}

export default CommentCard;
