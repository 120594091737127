import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Main from './containers/Main';
import AuthPage from './containers/AuthPage';
import ConnectPage from './containers/ConnectPage';
import './index.css';
import PrivateRoute from './containers/PrivateRoute';
import NewContent from './containers/NewContent';
import Categorias from './containers/Categorias';
import Contents from './containers/Contents';

import Comments from './containers/Comments';
import Media from './containers/Media';
import Users from './containers/Users';
import ContentAnalytics from './containers/ContentAnalytics';
import Profile from './containers/Profile';

import { SocketProvider } from 'socket.io-react';

class App extends Component {
  render() {
    return (
      
      <SocketProvider>
        <BrowserRouter>
            <Switch>
              <Route exact path="/connect/:provider" component={ConnectPage} />
              <Route exact path="/auth/:authType/:id?" component={AuthPage} />  
              <PrivateRoute allowed={["Administrator"]} path='/' component={Main}/>            
              <PrivateRoute allowed={["Administrator"]} path='/new' component={NewContent}/>
              <PrivateRoute allowed={["Administrator"]} path='/contentanalytics' component={ContentAnalytics}/>
              <PrivateRoute allowed={["Administrator"]} path='/categorias' component={Categorias}/>
              <PrivateRoute allowed={["Administrator"]} path='/contents' component={Contents}/>
              <PrivateRoute allowed={["Administrator"]} path='/comments' component={Comments}/>
              <PrivateRoute allowed={["Administrator"]} path='/media' component={Media}/>
              <PrivateRoute allowed={["Administrator"]} path='/new' component={NewContent}/>
              <PrivateRoute allowed={["Administrator"]} path='/users' component={Users}/>
              <PrivateRoute allowed={["Administrator"]} path='/edit:id?' component={NewContent}/>
              <PrivateRoute allowed={["Administrator"]} path='/profile' component={Profile}/>
              
            </Switch>
        </BrowserRouter>
      </SocketProvider>
    );
  }
}

export default App;
